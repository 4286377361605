.heading {
  display: flex;
  align-items: center;

  .code {
    margin-left: auto;
    font-size: 0.875rem;
    color: $gray-600;
  }

  .luxury-level {
    display: flex;
    margin-left: 1rem;

    svg {
      fill: $secondary;
      height: 1rem;
    }
  }
}

#booking-widget{
  h2, h3 {
    text-align: center;
    border-bottom: none;
  }
  h3 {
    color: $headings-color-detail;
  }
}
.detail-block, #block-helper-similar-holiday-homes, #block-helper-recently-watched-4 {
  padding-top: 2rem;
  padding-bottom: 2rem;
  h2 {
    font-size: 1.75rem;
    border-bottom: 1px solid $border-color;
    margin-bottom: 2rem;
    color: $headings-color-detail;
    line-height: 1;
    padding-bottom: 0.75rem;
  }
}
.detail-block {
  h2.holiday-home-xl {
    font-size: 1.5rem;
    border-bottom: none;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
}

.container.accommodations {
  margin-top: 3rem;
}

.alternate {
   @include alternate;
}

.side {
  display: flex;
  align-items: center;

  .links {
    margin-left: auto;

    .addthis_toolbox.addthis_32x32_style span {
      border-radius: $border-radius;

      svg {
        vertical-align: inherit;
      }
    }
  }
}

.field-body{
  p:last-child{
    margin-bottom:0;
  }
}

ul.trust-features {
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
  justify-content: space-around;

  li {
    list-style: none;
    color: $gray-600;
    line-height: 1rem;
    margin: 1rem;
    margin-right: 2rem;
    display: block;
  }

  svg {
    height: 1.5rem;
    fill: $gray-600;
    margin-right: 0.375rem;
  }
}

// Read more description only visible on small.
@include media-breakpoint-up(md) {
  .show-for-small-only {
    display: none;
  }
}

@include media-breakpoint-down(md) {
  .hide-for-small {
    display: none;
  }
  .show-for-small-only a {
    color: $link-color;

    &:hover {
      text-decoration: none;
    }
  }
}

#features, #layout, #area {
  h3 {
    font-size: 1.25rem;
    @include media-breakpoint-down(lg) {
      font-size: 1rem;
      font-weight: $font-weight-bold;
    }
    color: $body-color;
  }

  ul {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
  }

  li {
    list-style: none;
    padding-left: 1rem;
    position: relative;

    svg {
      height: 0.75rem;
      position: absolute;
      left: 0;
      top: 0.375rem;
      fill: $secondary;
    }

    @include media-breakpoint-only(md) {
      font-size: 0.875rem;
      svg {
        top: 0.3125rem;
      }
    }
  }
}

#area {
  .map-embed iframe {
    height: 400px;
    width: 100%;
  }
}

.has-inline-icon {
  display: flex;
  line-height:1;
  align-items: center;
  svg {
    height: 1.2em;
    width: auto;
  }
}

.intro {
  @include media-breakpoint-down(sm) {
    .btn {
      margin-bottom: 1rem;
    }
  }
}

.contact-photo{
  margin: 1rem auto;
  text-align: center;
  img{
    width:100px;
    height:auto;
    border-radius: 100%;
    margin-bottom:1rem;
  }
}

.contact-cards {
  @include media-breakpoint-up(lg) {
    display: flex;
  }
  width: 100%;
  justify-content: stretch;
  .card {
    @include media-breakpoint-up(lg){
      flex: 1 1 0;
      padding: 2rem .25rem 2rem .25rem;
    }
    @include media-breakpoint-down(lg){
      border:none;
    }
    font-size: .8rem;
    font-weight: normal;
    text-align: center;
    margin: $gutter/2;
    svg, img {
      margin: 10px auto;
      height: 40px;
      width: 40px;
      border-radius: 10px;
    }

    a {
      display: block;
      border: $gray-300 1px solid;
      margin-top: .75rem;
      color: $gray-600;
      margin-left: auto;
      margin-right: auto;
      &:hover {
        background: $gray-800;
        color: white;
      }
    }
    svg {
      fill: $gray-500;
    }
    img {
      border-radius: 100%;
    }
  }
}
