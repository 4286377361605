#footer {

  border-top: $footer-border-top;
  background: $footer-background-color;

  .container {
    margin-top: 3rem;
    margin-bottom: 3rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    > * {
      padding-right: $half-gutter-width;
      width: 25%;
    }

    @include media-breakpoint-down(lg) {
      margin-top: 0;
      margin-bottom: 2rem;
      .block {
        margin-top: 2rem;
      }
      > * {
        width: 50%;
      }
    }
    @include media-breakpoint-down(sm) {
      > * {
        width: 100%;
      }
    }
  }

  h3 {
    color: $footer-text-color-header;
  }

  ul {
    margin: 0;
    padding: 0;

    &.links {
      margin-top: 0.5rem;
    }
  }

  li {
    list-style: none;
    display:flex;
    align-items: baseline;
    justify-content: flex-start;
  }

  li, div {
    vertical-align: middle;
  }

  li, div, a {
    color: $footer-text-color;
  }

  svg {
    height: 0.75rem;
    fill: $footer-text-color;
    margin-right: 0.5rem;
    min-width: 0.5rem;
  }

  .bottom {
    background: $gray-900;
    padding: $half-gutter-width 0;

    li {
      display: inline-block;
      margin: 0 $half-gutter-width;
    }
  }

  .business-hours {
    font-size: 0.875rem;
  }

  .follow {
    margin-top: 0.5rem;

    .title {
      display: none;
    }
    display:flex;
    align-items: center;
    a{

      display:flex;
      align-items: center;
    }
    svg {
      height: 1rem;
      margin-left: 0.75rem;
      margin-right: 0;
    }
  }
}

#bottom {
  background: $bottom-footer-bg-color;
  border-top: $footer-border-top 1px solid;
  > .container {
    text-align: center;
    padding: 1rem 0;
    > * {
      display: inline-block;
    }
    @include media-breakpoint-down(md) {
      text-align: left;
    }
    ul.menu,
    > ul {
      margin: 0;
      padding: 0;
      display: inline-block;
      list-style: none;
      > li {
        list-style: none;
        padding: 0;
        margin: 0 1rem;
        display: inline-block;
        font-size: .9rem;
        @include media-breakpoint-down(md) {
          display: block;
        }

        color: $bottom-footer-text-color;

        a {
          color: $bottom-footer-text-color;
        }
      }
    }
  }
}

