/* Admin menu */
@include media-breakpoint-up(lg) {
  body.adminimal-menu {
    margin-top: 29px;
  }
}

@include media-breakpoint-down(lg) {
  #admin-menu-menu li:not(:first-child),
  #admin-menu-account .welcome {
    display: none;
  }
}

#admin-menu-wrapper {
  margin: 0 auto;
  max-width: 1140px;
  padding: 0;
}

body.adminimal-menu #admin-menu .dropdown li.admin-menu-action.admin-menu-account a {
  background: none !important;
  background-image: linear-gradient(#4e9fea 0%, #417ed2 100%) !important;
  background-image: -ms-linear-gradient(#4e9fea 0%, #417ed2 100%) !important;
  background-image: -webkit-linear-gradient(#4e9fea 0%, #417ed2 100%) !important;
}

body.adminimal-menu #admin-menu .dropdown li.admin-menu-action.admin-menu-account a:hover {
  background-image: linear-gradient(#1c9fea 0%, #0a7ed2 100%) !important;
  background-image: -ms-linear-gradient(#1c9fea 0%, #0a7ed2 100%) !important;
  background-image: -webkit-linear-gradient(#1c9fea 0%, #0a7ed2 100%) !important;
}

body.adminimal-menu div#admin-menu {
  background: #333 !important;
}

#admin-menu li {
  border-left: 1px solid #000;
}

#admin-menu .dropdown .welcome span {
  border: medium none !important;
  color: #ccc;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 12px;
  font-style: italic;
  line-height: 18px;
  padding: 5px 10px;
}

body.adminimal-menu div#admin-menu a, body.adminimal-menu div#admin-menu span {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

#admin-menu .dropdown .welcome:hover {
  background: none !important;
}
