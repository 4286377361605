.mobile-header {
  padding: 1rem 0;
  background: $mobile-header-background;
  color: $mobile-header-color;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo-mobile {
      white-space: nowrap;
      font-family: $headings-font-family;

      a {
        color: $mobile-header-color;
        text-decoration: none;
        font-size: 1.5em;
        @include media-breakpoint-down(sm) {
          font-size:1.3em;
        }
      }
      &.with-logo a img{
        max-height:3.2rem;
        max-width:8rem;
        min-width:5rem;
        min-height:2rem;
      }
    }

    .mobile-search-toggle,
    .mobile-menu-toggle {
      border: 1px solid rgba($mobile-header-color, .5);
      height: 2em;
      padding: .5em;
      text-transform: lowercase;
      display: flex;
      align-items: center;
      font-size: 1.3em;
      border-radius: $border-radius;

      &:hover {
        background: rgba($mobile-header-color, .2);
      }
    }

    .mobile-menu-toggle {
      cursor: pointer;
      font-weight: $font-weight-bold;

      .hamburger {
        outline: none;
        margin-top: $hamburger-layer-spacing/2;
      }
      .label{
        margin-left: .5rem;
      }

      @include media-breakpoint-down(sm) {
        .label{
          font-size:.8em;
        }
      }
      @include media-breakpoint-down(xs){
        .label{
          display:none;
        }
      }

      .mobile-menu-toggle-active & {
        background: rgba(white, .2);
      }
    }

    .mobile-search-toggle {
      margin-right: 1rem;
      margin-left: auto;

      .page-book & {
        display:none;
      }
      .search-toggle-active & {
        background: rgba(white, .2);
      }

      svg {
        fill: currentColor;
        height: 1em;
        width: 1em;
      }
    }
  }
}

.mobile-menu {
  display: none;
  background:$mobile-menu-background;
  color:$mobile-menu-color;
  @include media-breakpoint-down(md) {
    .mobile-menu-toggle-active & {
      display: block;
    }
  }
  ul.nav {
    display: block;
    &:first-child {
      font-weight: $font-weight-bold;
    }
    li {
    }
  }
  a {
    &:hover {
      background: darken($mobile-menu-background, 10);
      color: $mobile-menu-color;
    }
  }
  .nav-link{

    border-bottom: 1px solid $mobile-menu-border-color;
    padding: 1rem;
    color:$mobile-menu-color;
  }
  .language-menu{
    .language-toggle{
      display:none;
    }
    .dropdown-menu{
      display:block !important;
      position:relative;
      background:none;
      padding:0;
      margin:0;
      border-radius:0;
      border:none;
      width:100%;
      a{
        display:flex;
        align-items: center;
        &:first-child{
          border-bottom:1px solid $mobile-menu-border-color;
        }
        width:100%;
        svg{
          margin-right:.5rem;
          height:1em;
        }
      }
    }
  }
}

#main-menu-footer-mobile {
  display: none;
  @include media-breakpoint-down(sm) {
    display: block;
    margin-bottom:2rem;
    ul {
      margin: 0;
      padding: 0;
    }
    li {
      list-style: none;
    }
    a {
      font-size: 1.25rem;
      display: block;
      border-top: 1px solid $border-color;
      padding: 0.5rem 0;
    }
  }

}
