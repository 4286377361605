.node-page.full {
  padding-bottom: 2rem;
}

.node-page > {
  .content, .row > .content {
    > p {
      margin-bottom: 1.5rem;
    }

    > p:first-child {
      font-size: 1.2em;
    }

    > h3 {
      color: $primary;
    }
  }

  .row > .image-slide {
    margin-bottom: 2rem;
  }
}
