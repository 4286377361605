// Sticky footer
html {
  height: 100%;
  scroll-behavior: smooth;
  body {
    height: 100%;
    display: flex;
    flex-direction: column;
    #footer {
      margin-top: auto;
    }
  }
}

#header{
  z-index:4;
}

.alert{
  margin: 2rem 0;
  ul {
    margin-bottom: 0;
  }
}


#content > h1 {
  margin-bottom: 1rem;
}
#content-top {
  .messages {
    margin-top: 1.5rem;
  }
}

.container#reliability{
 > div{
   display:flex;
   justify-content: center;
   align-items: center;
   flex-wrap: wrap;
   > div {
     margin: 0 1rem 1rem 1rem;
     .label {
       margin-right:1rem;
     }
     flex-wrap: wrap;
     justify-content: center;
     align-items: center;
     display:flex;
   }
 }
}

@font-face {
  font-family: 'icons';
  src: url('#{$base_theme_path}/../flean_rfov/fonts/fontello/font/icons.eot?1558704154');
  src: url('#{$base_theme_path}/../flean_rfov/fonts/fontello/font/icons.eot?1558704154#iefix') format('embedded-opentype'),
  url('#{$base_theme_path}/../flean_rfov/fonts/fontello/font/icons.woff2?1558704154') format('woff2'),
  url('#{$base_theme_path}/../flean_rfov/fonts/fontello/font/icons.woff?1558704154') format('woff'),
  url('#{$base_theme_path}/../flean_rfov/fonts/fontello/font/icons.ttf?1558704154') format('truetype'),
  url('#{$base_theme_path}/../flean_rfov/fonts/fontello/font/icons.svg?1558704154#icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

.side-block {
  padding: $half-gutter-width;
  @include media-breakpoint-up(lg) {
    @include card;
  }
  @include media-breakpoint-down(md){
    padding-left:0;
  }
  .address{
    margin-left:0;
    > * {
      display:inline;
    }
  }
}

#block-helper-search-search-holiday-homes,
#block-helper-event-helper-event-search-form {
  .not-front & {
    @include media-breakpoint-down(sm) {
      margin-left: -$gutter-mobile;
      margin-right: -$gutter-mobile;
      width: calc(100% + 2 * #{$gutter-mobile}) !important;
    }
  }
}

.cke_editable{
  background: $white;
  padding: 1rem;
}
