.ferry-alert {
  display: flex;
  position: relative;
  padding: 1.5rem 2.5rem 1.5rem 3.5rem;
  @include media-breakpoint-down(md) {
    padding-right: 1rem;
    p {
      margin-right: 0.25rem;
    }
  }
  justify-content: space-between;
  > svg {
    height: 1.5rem;
    width: 1.5rem;
    fill: $body-color;
    position: absolute;
    left: 1rem;
    top: 1.5rem;
  }
  h2 {
    color: $body-color;
    font-size: 1.5rem;
    line-height: 1;
  }
  button {
    margin: auto 0;
  }
}

.modal.ferry-capacity {
  z-index: 9999;
  .modal-content {
    background: $body-bg;
  }
  .modal-header {
    border-bottom: none;
  }
  .modal-body {
    padding: 0;
  }
  .modal-footer {
    border-top: none;
    justify-content: center;
  }
  .modal-title {
    font-size: 2rem;
    margin: 0;
  }
  .spinner-border {
    margin-left: 1rem;
    height: 3rem;
    width: 3rem;
  }
}

#ferry-capacity, .modal.ferry-capacity {
  .filters {
    background: $body-bg;;
    display: flex;
    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
    padding: 1rem;
    > div > svg {
      height: 1.375rem;
      width: 1.375rem;
      margin-right: 0.375rem;
      margin-bottom: 0.25rem;
      position: absolute;
      top: 0.5rem;
      right: 0.125rem;
      pointer-events: none;
      fill: $input-color;
    }
  }

  .ferry-date, .ferry-route {
    position: relative;
    margin-right: 1rem;
    @include media-breakpoint-down(md) {
      margin-right: 0;
    }
  }
  #ferry-date {
    cursor: pointer;
    @include media-breakpoint-down(md) {
      margin-bottom: 1rem;
    }
  }
  #ferry-route {
    text-align: left;
    display: block;
    width: 100%;
    height: $input-height;
    padding: $input-padding-y $input-padding-x;
    font-family: $input-font-family;
    @include font-size($input-font-size);
    font-weight: $input-font-weight;
    line-height: $input-line-height;
    color: $input-color;
    background-color: $input-bg;
    background-clip: padding-box;
    border: $input-border-width solid $input-border-color;
    @include border-radius($input-border-radius, 0);
    @include box-shadow($input-box-shadow);
    @include transition($input-transition);
    padding-right: 2.25rem;
    &.dropdown-toggle::after {
      display: none;
    }
  }
  .calendar {
    position: absolute;
    width: 20rem;
    height: 15rem;
    z-index: 99;
    left: 0;
    top: 2.375rem;
    @media only screen and (max-width: 360px) {
      left: -1.5rem;
    }
  }

  table {
    width: 100%;
  }
  tr {
    border-bottom: $border-color 1px solid;
    &:first-child {
      border-top: $border-color 1px solid;
    }
    &:nth-child(even) {
      background: $body-bg;
    }
    &:nth-child(odd) {
      background: $white;
    }
  }
  td {
    padding: 1.75rem;
    //vertical-align: top;
  }
  .availability {
    position: relative;
  }
  .number-of {
    color: $legenda-available-color;
    margin-right: 0.375rem;
  }
  .info {
    position: absolute;
    color: $gray-600;
    font-size: 0.75rem;
    top: 3.125rem;
  }
  @include media-breakpoint-down(md) {
    tr {
      display: block;
      padding: 0 1rem 1rem 1rem;
    }
    td {
      display: inline-block;
      width: 33.33%;
      padding: 1rem 0;
      &.availability {
        padding-top: 0;
        width: 50%;
        div span {
          font-size: 0.875rem;
        }
      }
      .info {
        top: 0;
        position: relative;
      }
    }
  }
}

#ferry-capacity {
  .filters {
    border-top: $border-color 1px solid;
  }
}

.modal.ferry-capacity {
  .filters {
    border-top: none;
  }
}
