.price-info {
  color: $body-color;

  .price-context-info {
    font-size: 0.7rem;
  }

  .original-price {
    font-size: 0.75rem;
    text-decoration: line-through;
    display: block;
    margin-top: 0.5rem;
    margin-bottom: .25rem;
  }

  .original-price + .price {
    color: $discount-color;
    margin-bottom: 0;
  }

  .prefix {
    display: block;
    font-size: .75rem;
  }

  .price, .price-values {
    font-size: 1.25rem;
    font-weight: $font-weight-bold;
    line-height: 1;
  }

  .discount {
    font-size: .9em;
    color: $discount-color;
    margin-bottom: .5rem;
  }
}
