@mixin grid-list($sizes, $class) {
  @each $breakpoint in map-keys($sizes) {
    .list {
      .row {
        .#{$class} {
          @include media-breakpoint-only($breakpoint) {
            flex: 0 0 #{map-get($sizes, $breakpoint)};
            max-width: #{map-get($sizes, $breakpoint)};
          }
        }
      }
    }
  }
}