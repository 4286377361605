
#sliding-popup {
  background: white !important;
  width: 100%;
  .popup-content {
    max-width: 95% !important;
    display:block;
  }
  box-shadow: 0 0 20px rgba(0, 0, 0, .4);

  #popup-text {
    margin: 20px 0;
    color: #666 !important;
    a {
      color: #666;
      text-decoration: underline;
    }
    p{
      margin:0;
    }
    h4,
    h2 {
      font-size: 1.6em;
      margin: 0 0 10px 0;
      color: $secondary !important;
    }
  }
  .find-more-button {
    display: none;
  }
  .decline-button{
    display:none;
  }
  .agree-button {
    outline: none;
    margin-top: 25px;
    @include media-breakpoint-down(md) {
      margin: 20px 0;
    }
    padding: 9px 30px 5px 30px;
    border: none;
    border-radius: 20px;
    box-shadow: 0 3px darken(green, 10);
    background-color: green !important;
    background-image: none;
    text-shadow: none;
    line-height: 1;
    color: #FFF;
    font-size: 18px;
    font-weight: $font-weight-bold;
    font-family: 'Helvetica', 'Arial', Sans-Serif;
    &:active{
      margin-top:28px;
      box-shadow:0 0px darken(green, 10);
    }
  }
}
