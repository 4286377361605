@include media-breakpoint-up(lg) {
  nav.main-menu {
    z-index: 999;
    > ul > li:hover > .level-2-wrapper {
      display: block;
    }

    > ul > li.has-children > .toggle {
      display: none;
    }

    .level-2-wrapper {
      border-top: white 1px solid;
      z-index: 300;
      display: none;
      position: absolute;
      background: $mega-menu-bg-color;
      left: 0;
      width: 100%;
      box-shadow: rgba(black, .2) 0 1rem 1rem;

      ul,
      ul, li {
        list-style: none;
        padding: 0;
        margin: 0;
      }

      > div > ul {
        padding: 3rem 0;
        display: flex;

        > li {
          margin-right: 5rem;
        }

        .nav-link {
          color: $mega-menu-text-color !important;
          font-weight: normal !important;
          margin-right: 0 !important;
          padding: .5rem 0 !important;
        }

        a.nav-link:hover {
          text-decoration: underline !important;
        }

        ul.level-2 {
          font-size: .9rem;

          .nav-link {
            font-weight: bold !important;
          }
        }

        ul.level-3 {
          font-size: .9rem;
        }
      }
    }
  }

  .header.with-mega-menu {
    z-index: 100 !important;

    .col {
      position: inherit;
    }
  }
}

@include media-breakpoint-down(md) {

  nav.main-menu {

    > ul > li.has-children > .toggle {
      float: right;
      display: block;
      cursor: pointer;
      padding: 1rem;
      color: $white;
      &:hover {
        text-decoration: none;
      }
      &:active {
        color: darken($mobile-menu-background, 10);
      }
      span {
        display: inline-block;
      }
    }
    > ul > li.has-children .level-2-wrapper {
      display: none;
    }
    > ul > li.has-children.expanded {
      .toggle span {
        transform: rotate(180deg);
        vertical-align: text-top;
      }
      .level-2-wrapper {
        display: block;
        background-color: darken($mobile-menu-background, 10);
      }
    }

  }
}

@include media-breakpoint-down(lg) {

  .level-2-wrapper {
    > .container {
      margin: 0;
      width: 100%;
      max-width: none;
      padding: 0;

      ul,
      ul, li {
        padding: 0;
        margin: 0;
        list-style: none;
      }

      ul.level-2 {
        font-weight: normal !important;
        font-size: .8rem;

        > li > .nav-link {
          padding-left: 2rem;
        }
      }

      ul.level-3 {
        font-size: .7rem;

        > li > .nav-link {
          padding-left: 3rem;
        }
      }
    }
  }
}
