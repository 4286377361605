.page-blocks {
  a  img{
    border-radius: $border-radius !important;
  }
  @include media-breakpoint-up(md) {
    .field-paragraphs & {
      margin-top: 0;
    }
    > .column {
      margin-bottom: 3rem;
    }
  }

  > .column {
    @include article_teaser;

    .highest-average-field .value {
      margin-left: 0.25rem;
    }
    h3 {
      background: none;
      padding: 0;
      position: relative;
      color: $dark;
      font-size:1.3rem;
    }

  }

  #rent {
    ul {
      padding: 0;
      list-style: none;

      li {
        position: relative;
        margin: .5rem 1.5rem;
        padding-left: 1.5rem;
        list-style: none;
        font-size: .9em;
      }
    }

    ul li {
      &:before {
        color: green;
        position: absolute;
        left: 0;
      }

      @include icon('ok');
    }
  }
}
