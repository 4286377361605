#block-helper-destinations, #block-helper-destinations-cities-only {
  ul, li {
    list-style: none;
  }
  h3{
    margin-top:1rem;
  }
  ul.cities{
    display:flex;
    flex-wrap:wrap;

    li{
      width:50%;
    }
  }
  ul.cities {
    padding-left: 0;
    svg {
      height: 0.75rem;
      vertical-align: baseline;
      fill: $link-color;
      margin-right: 0.5rem;
    }
  }
}