/* Required Bootstrap files */
@import "mixins/custom_grid";
@import "mixins/holiday_home";
@import "mixins/teaser_discounts";
@import "mixins/spinner";
@import "mixins/reviews";
@import "mixins/icons";
@import "mixins/tooltip";
@import "mixins/card";
@import "mixins/call_to_action";
@import "mixins/article_teaser";
@import "mixins/alternate";
@import "mixins/body";
