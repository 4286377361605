.ui-widget-overlay {
  background: rgba(black, .2);
}

.ui-dialog {
  background: white;
  border-radius: $border-radius;
  box-shadow: rgba(black, .5) 0 0 1rem;
  padding: 0;
  max-width: calc(100% - 1rem);
  margin:.5rem;
  .ui-dialog-titlebar {
    background: $primary;
    border-radius: $border-radius $border-radius 0 0;
    color: white;
    padding: 1rem;
    font-weight: $font-weight-bold;
  }

  .ui-dialog-buttonpane {
    background: $gray-200;
    border-radius: 0 0 $border-radius $border-radius;

    button {
      @include call_to_action();
      font-weight: $font-weight-bold;
      border-radius: $border-radius;
      &.cancel{
        background-color:$gray-500;
        border-color:darken($gray-500,10);
      }
    }
  }

  form p {
    font-size: .9em;
  }
  form{
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .form-item{
      margin-bottom:.3rem;
    }
    div[data-track-funnelstepview] {
      display:contents;
    }
    @include media-breakpoint-up(sm){
      .field-name-field-booking-mail{
        width:calc(100% / 2 - .5rem);
      }
      .field-name-field-newsletter{
        width:calc(100% / 2 - .5rem);
      }
      .field-name-field-initials,
      .field-name-field-surname{
        width:calc(100% / 2 - .5rem);
      }
      .field-name-field-phone,
      [class*="form-item-field-booking-country-"]{
        width: calc(100% / 2 - .5rem)
      }
      .form-item-field-to,
      .form-item-field-from{
        width:calc(100% / 2 - .5rem);
      }
    }
  }
  > div {
    select,
    input,
    label{
      margin-top:0;
      font-size:.9em;
    }

  }

}

#dialog-form {
}
