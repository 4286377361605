.btn.has-icon {
  display:flex;
  align-items:center;
  svg {
    height:1em;
    margin-left:.5em;
    fill: currentColor;
  }
}
.btn-primary, .btn-secondary {
  color: $white;
}
