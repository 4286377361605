.page-book-thank-you {
  .breadcrumb-wrapper {
    margin: 0 !important;
  }

  h1.title {
    max-width: 800px;
    margin: 0 auto;
    font-size: 2.4em;
    margin-bottom:.5rem;
  }

  .container.not-node {
    max-width: 800px;
    @include card;
    padding: 3rem;
    @include media-breakpoint-down(md) {
      padding:1rem;
      margin:1rem;
      width:calc(100% - 2rem);
    }
  }

  h2 {
    font-size: 1.5em;
    margin-top: 2rem;

  }

  .info {
    color: $gray-600;
    font-style: italic;
  }

  strong {
    font-size: 1.15em;
    padding-top: 10px;
    display: block;
  }

  table {
    width:100%;
    tr {
      margin:.3rem 0;
      display:block;
      td:first-child {
        width: 34%;
        min-width:200px;
      }
      td{
        @include media-breakpoint-down(xs) {
          display: block;
          width: 100% !important;
          &:first-child{
            font-weight:bold;
            border-top: 1px solid $light;
            margin-top: 1rem;
            padding-top:1rem;
          }
        }
      }
    }
  }
}
