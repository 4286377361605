h1, h2, h3, h4, h5, h6 {
  font-weight: $font-weight-bold;
}
h4 {
  color: $gray-700;
  font-size: 1.25rem;
}

h1 {
  margin-bottom: 1rem;
}
h3, h4, h5, h6 {
  color: $body-color;
}
.field-body-short,
.node-page .content,
.field-body,
.full-description,
.text-based,
.h-100  .content{
  @include body;
}
