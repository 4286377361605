$review_label_width: 231px;
$review_cell_height: 2.5rem;

#helper-review-form{
  margin: 0 auto;
  padding:1rem;
  max-width:800px;
  @include media-breakpoint-down(md){
    margin:1rem;
  }
  @include card();
}
h1.title {
  .page-node-add-review & {
    @include media-breakpoint-up(md) {
      max-width: 800px;
      margin: 0 auto;
      margin-top:3rem;
      font-size:1.5em;
    }
  }
}

.container.not-node > * {

  .page-node-add-review & {
    @include media-breakpoint-up(md) {
      max-width: 800px;
      margin: 0 auto;
      background: white;
      padding: 1rem;
      @include media-breakpoint-up(lg) {
        padding:3rem;
      }
      border-radius: $border-radius;
      display: flex;
      flex-direction: column;
      > .holiday-home {
        order: -1;
        width: 200px;
        margin-left: $review_label_width;
        .title{
          display:none;
        }
      }
    }
      .holiday-home img{
        width:100%;
        height:auto;
      }


    &#block-helper-similar-holiday-homes {
      display: none;
    }
  }
}

#helper-review-form {
  @include media-breakpoint-up(md) {

    h1,
    h2 {
      margin-top: 3rem;
      font-size: 1.25rem;
      font-weight: $font-weight-bold;
      margin-left: $review_label_width;
    }
    h1 {
      font-size: 1.5rem;
    }

    .labels {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin-left: $review_label_width;

      border-bottom: 1px solid $border-color;

      > .label {
        width: 100%;

        border-top: 1px solid $border-color;
        border-left: 1px solid $border-color;
        padding: 10px;
        text-align: center;

        &:last-child {
          border-right: 1px solid $border-color;
        }
      }
    }

    #edit-travel-group {
      margin-left: $review_label_width;

      > .form-item {
        margin: .5rem 0;

        label {
          font-weight: normal;
        }
      }
    }

    .form-type-textarea,
    .form-type-textfield {
      display: flex;

      label {

        margin-top: .5rem;
        min-width: $review_label_width;
        text-align: right;
        padding-right: 10px;
      }

      .form-textarea-wrapper {
        width: 100%;
      }
    }

    .form-submit {
      margin-left: $review_label_width;
    }

    > div > .form-item.form-type-radios:not(.form-item-travel-group) {
      margin: 0;

      > label {
        margin-bottom: 0;
      }

      justify-content: space-evenly;
      display: flex;
      align-items: center;

      > label {
        text-align: right;
        padding: 10px;
        min-width: $review_label_width;
        height: $review_cell_height;
        // font-weight: normal;
        //   border-left: 1px solid $border-color;
        //   border-bottom: 1px solid $border-color;
      }

      .form-radios {
        justify-content: space-evenly;
        display: flex;
        align-items: center;
        width: 100%;

        &:hover {
          background: rgba(black, .02);
        }

        .form-item {
          padding: 10px;
          width: 100%;
          position: relative;
          border-bottom: 1px solid $border-color;
          border-right: 1px solid $border-color;
          height: $review_cell_height;
          margin-bottom: 0;
          text-align: center;

          &:first-child {
            border-left: 1px solid $border-color;
          }

          input[type="radio"]:checked + label {
            background: rgba(black, .02);
          }
          label {
            font-size: 0;
            position: absolute;
            height: 100%;
            left: 0;
            top: 0;
            cursor: pointer;
            padding: 0;
            width: 100%;
            margin-bottom: 0;
          }
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .labels{
      display:none;
    }
    .form-type-radio{
      margin:0;
      label{
        font-weight:normal;
      }
    }
    .form-submit{
      margin-bottom:2rem;
    }
  }
}
