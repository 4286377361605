@mixin article_teaser {
  &:hover {
    text-decoration: none;
  }
  .h-100 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
  }
  h3 {
    margin: 0.75rem 0;

    &.mt-0 {
      margin-top: -0.375rem !important;
    }

    svg {
      height: 1rem;
      fill: $white;
      margin-left: 0.25rem;
      @include media-breakpoint-up(sm) {
        display: none;
      }
    }
  }
  .content {
    display: contents;
    color: $body-color;
  }
  .read-more.btn {
    margin-top: auto;
    position: relative;
    @include media-breakpoint-down(sm) {
      display: none;
    }
    top: 1rem;
    margin-bottom: 1rem;
  }
  @include media-breakpoint-only(sm){
    padding:1rem;
    margin: 1rem;
    box-sizing:border-box;
    max-width:calc(50% - 2rem);
    @include card;
  }
  &.no-image,
  &.block_html {
    @include media-breakpoint-down(xs) {
      margin-bottom: 2rem;
    }
    @include media-breakpoint-down(md) {
      h3 {
        background: none;
        padding: 0;
        position: relative;
        color: $dark;

        svg {
          display: none;
        }
      }
      .content {
        display: block;

        .btn.read-more {
          display: inline;
        }
      }
    }
  }
  &:not(.no-image):not(.block_html) {
    @include media-breakpoint-down(xs) {
      margin-bottom: 2rem;
      .image,
      .img-fluid {
        max-width: none;
        width: 100%;

        img {
          width: 100%;
        }
      }
      .column {
        margin-bottom: 1rem;
      }
      .content {
        padding-bottom: 0;
      }

      h3 {
        color: $white !important;
        position: absolute !important;
        margin: 0;
        left: 0;
        bottom: 0;
        padding: 1rem 1rem 1rem 1rem !important;
        width: 100%;
        background: linear-gradient(
            rgba(0, 0, 0, 0),
            rgba(0, 0, 0, 0.5)
        ) !important;
        text-shadow: rgba(black, .4) 1px 1px 1px;
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1rem !important;

        svg {
          height: 14px;
          width: 14px;
        }
      }
    }
    @include media-breakpoint-down(xs) {
      .content {
        display: none;
      }
    }
  }
}
