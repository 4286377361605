@mixin reviews() {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  .review {
    width: calc(100% / 3 - 3rem);
    @include media-breakpoint-down(lg) {
      width: calc(100% - 2rem) !important;
    }
    display: flex;
    padding: 1rem;
    border-radius: $border-radius;
    position: relative;
    background: white;
    box-shadow: rgba(black, .2) .3rem .3rem .8rem;
    margin-bottom: 8rem;

    p {
      margin: 0 !important;
    }

    .content {
      flex-direction: column;
      justify-content: space-around;
      display:inline;
      a {
        font-style: normal;
        font-weight: $font-weight-bold;
        display: inline;
        white-space:nowrap;
      }
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 2rem;
      width: 0;
      height: 0;
      border: 1.6rem solid transparent;
      border-top-color: white;
      border-bottom: 0;
      border-left: 0;
      margin-left: -.8rem;
      margin-bottom: -1.6rem;
    }
  }
  .meta {
    position: absolute;
    bottom: -6.5rem;
    display: flex;
    left: 0rem;

    .image {
      float: left;
      background: $white;
      border: 1px solid $border-color;
      border-radius: $border-radius;
      transform: rotate(-10deg);
      padding: 0.25rem;
      margin: 0.5rem 0 0.5rem 0;
      position: relative;
      z-index: 0;
    }

    .name {
      color: $gray-800;
      float: left;
      margin: 1rem 0 0 1rem;
      font-weight: $font-weight-bold;
    }
  }
}
