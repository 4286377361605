.node-event,
.node-company {
  > .container:first-child {
    padding-bottom: 3rem;
  }

  .side-block {
    .event-dates,
    .location-wrapper,
    .phones,
    .website {
      vertical-align: middle;
      font-size: .8rem;
      line-height: 1.2;
      margin-top: .5rem;
      padding-top: .5rem;
    }
    .event-dates{
      display:flex;
      align-items: center;
    }

    svg {
      height: 2.3rem;
      width: 2.3rem;
      padding: .7rem;
      color: white;
      fill: white;
      border-radius: .2rem;
      margin-right: 1rem;
      background: $gray-400;
      box-sizing: border-box;
    }

    .location-wrapper {
      display: flex;
      align-items: center;
    }

    a.btn {
      margin-bottom: 1rem;
    }
  }
  .external-booking-link {
    display:flex;
    align-items: center;
    a {
      margin: 2rem auto !important;
    }
  }
  #event-dates{

    .event-dates-more-wrapper{
      width:100%;
      margin-top:2rem;
      display:flex;
      flex-wrap:wrap;
    }
    .container{
      display:flex;
      flex-wrap: wrap;
      .event-date-row-read-more{
       flex: 0 1 100%;
        margin:2rem 0;
        a{
          box-sizing: border-box;
          margin: 1rem auto;
        }
      }
      h2, div{
        width:100%;
      }
      .event-date-row{
        width:calc(100% / 3);
        @include media-breakpoint-down(md){
          width:calc(100% / 2);
        }
        @include media-breakpoint-down(sm){
          width:100%;
        }
      }
    }
  }
  .event-date-row {
    padding: 1rem 0;
    font-weight: bold;
    border-bottom: 1px solid $border-color;
  }
  .event-date-row-read-more{
    margin-top:1rem;
  }
}

.events.list .event {
  .show {
    .price-from,
    .date {
      color: $body-color;
    }

    .price-from {
      font-weight: bold;
      @include media-breakpoint-up(md) {
        margin-bottom: .4rem;
      }
    }

    .date {
      font-size: .8rem;
      margin-bottom: .25rem;
    }
  }
}
