


.page-book {

  .loading{
    display:none;
    background:rgba(white,.4);
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index: 99;
    @include spinner;
  }
  &.updating-funnel,
  &.updating-overview {
    .loading {
      display: block;
    }
  }
  .use-api{
    .loading{
      position:fixed;
    }
    .overview-col .loading{
      display:none !important;
    }
  }
  #company-elements-wrapper{
    position:relative;
  }
  #helper-search-block-content-form {
    display: none;
  }

  #block-helper-recently-watched-4,
  #block-helper-similar-holiday-homes {
    display: none;
  }

  #block-system-main {
    position: relative;
  }

  .menu-small {
    display: none;
  }
}

#booking-funnel {
  .messages {
    margin-bottom: 2rem;
  }
}

#main .helper-book-funnel {
  input[type="submit"]{
    @include call_to_action;
  }

  @include media-breakpoint-up(lg) {
    .overview.card{
      border:none;
      background:none;
      .card-body{
        &:first-child {
          padding-top: 0;
        }
        background:none;
        .accommodation{
          .image{
            margin-bottom:1rem;
          }
        }
      }
    }
  }
  .accommodation {
    .image {
      img {
        border-radius: $border-radius;
      }
    }

    .content {
      display: flex;
      flex-direction: column;

      h3 {
        font-weight: $font-weight-bold;
        font-size: 1.25rem;
      }

      .rating {
        display: flex;
        margin-top: auto;
        align-items: center;
        justify-self: flex-end;
        left: 0;
        white-space: normal;

        .amount {
          background: $primary;
          padding: 4px;
          border-radius: $border-radius;
          color: white;
          margin-right: 1rem;
        }
      }

    }
  }

  // Mobile overwrites.
  @include media-breakpoint-down(md) {

    .container {
      display: flex;
      flex-direction: column;

      h1 {
        order: -3000;
      }

      #booking-funnel-overview {
        order: -1;
      }

      .pre-booking-instructions {
        order: -2000;
      }

      .helpdesk {
        order: 99;
      }


      .post-booking-instructions {
        order: 999;
      }


      // Allow sorting elements by setting display:contents.
      .row {
        display: contents;

        .col {
          display: contents;

          .overview-wrapper {
            display: contents;

            .overview-inner {
              display: contents;
            }
          }
        }
      }

      .overview {
        @include media-breakpoint-up(xs) {
          .accommodation {
            display: flex;
            .image {
              margin-right: 1em;
              width: 30%;
              max-width: 200px;
              margin-bottom:0;
            }
          }
        }
        @include media-breakpoint-down(xs) {
          .accommodation {
            display:block;
            .image {
              margin:0;
              width:100%;
              max-width:none;
              margin-bottom:1rem;
            }
          }
        }



        .costs {
          h3 {
            display: none;
          }

          .mobile-summary {
            margin: 1rem 0;

            display: flex;
            justify-content: space-between;

            .total-cost-mobile {
              font-size: 1.2em;
            }

            .cost-details-toggle {
              align-self: center;
              .show-cost-details &{
                svg{
                  transform: rotate(180deg);
                }
              }
            }
          }

          #cost-overview {
            display: none;
            .show-cost-details &{
              display:block;
              padding-top:1rem;
              border-top:1px solid $booking-funnel-line-color;
            }
          }
        }
      }
    }
    .col {
      padding: 0px;

      .form-radios {
        display: block !important;
      }

      .form-type-textarea {
        margin-right: 0 !important;
      }

      h2 {
        font-size: 1.2rem !important;
      }

    }

  }


  .card-wrapper {
    margin-bottom: 2rem;
    border: 1px solid $border-color;
    background: white;
    padding: 2rem;
    border-radius: 5px;

    input[name="stripe_cardholder_name"] {
      width: 100%;
      padding: 15px !important;
      line-height: 1em !important;
    }

    input::placeholder {
      color: #666 !important;
    }

    #card-element {
      padding: 15px;
      border: 1px solid $border-color;
      background: white;
      border-radius: 5px;
    }
  }


  .ajax-progress {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    left: 0;
  }

  margin-top: 20px;

  .previous {
    border-top: none !important;

    input[type="submit"],
    a {
      background: none;
      font-weight: normal;
      padding: 0;
      font-size: 14px;
      border: none;
      border-radius: 0;
      text-shadow: none;
      box-shadow: none;
      color: #666;
      text-decoration: underline;
    }
  }

  h1 {
    margin: 20px 0;
    width: 100%;
    float: none;
  }


  h4 {
    font-weight: $font-weight-bold;
  }

  #edit-booking-type {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .form-item {
      margin: 0;
      margin-right: 0 !important;
      width: 50% !important;

      input[type="radio"] ~ label {
        width: 100% !important;
      }

      display: flex;
    }
  }

  margin-bottom: 50px;

  .booking {
    @media (max-width:400px) {
      font-size:.9rem;
    }
    .pre-booking-instructions {
      margin-top: 0;
      margin-bottom:2em;
      p:last-child{
        margin-bottom:0;
      }
    }

    @include media-breakpoint-down(md) {
      width: 100%;
      flex-basis: auto;
    }

    #steps-wrapper {
      @include media-breakpoint-up(lg) {
        margin-top: -1.6rem;
      }
      .ajax-loading--helper-flean-manager-validate-form & {
        @include spinner;

        &:before {
          content: ' ';
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          left: 0;
          background: rgba(white, .5);
          z-index: 99;
        }
      }

      @include media-breakpoint-down(xs) {
        input {
          float: none;
        }
      }
      #edit-company,
      #company-elements-wrapper {
        .min-guests-warning{
          margin-top: 1rem;
          margin-bottom:2rem;
          font-size:.9rem;
        }
        .fieldset-wrapper {
          display: flex;
          flex-flow: wrap;

          .form-item {
            @include media-breakpoint-down(xs) {
              flex: 0 0 100%;
              select {
                width: 100% !important;
              }
            }
            flex: 0 0 50%;

            select {
              width: auto;
            }
          }
        }
      }
      > * > fieldset,
      > fieldset {
        background: $step-background;
        padding: $booking-step-margin;
        position: relative;
        margin-bottom: $booking-step-margin;

        > legend {
          margin-bottom: 2.5rem;
          bottom: -3rem;
          position: relative;
          h2 {
            border-bottom: 1px solid $booking-funnel-line-color;
            font-size: 1.1em;
            padding-bottom: .5em;
          }
        }

        border-radius: 5px;

        > legend,
        > label {
          line-height: 32px;
          font-weight: $font-weight-bold;
        }

        .fieldset-description,
        .description {
          font-style: italic;
          font-size: 12px;
          color: #aaa;
          line-height: normal;
        }

        select {
          background: white;
          min-width: 60px;
          line-height: 50px;
          margin-top: 0.25rem;
          margin-bottom: 5px;
        }

        .form-radios {
          display: flex;
          justify-content: start;

          input ~ label {
            &:before,
            &:after {
              top: -2px;
            }
          }

          > div {
            margin-right: 10%;
            margin-top: 1em;

            &:last-child {
              margin-right: 0;
            }
          }
        }

        input[type="checkbox"] {
          display: none;

          ~ label {
            float: left;
            font-weight: $font-weight-bold;
            width: 60%;
            cursor: pointer;

            &:after {
              content: ' ';
              background: white;
              width: 20px;
              height: 20px;
              border: 1px solid darken($border-color, 10);
              border-radius: 2px;
              line-height: 20px;
              padding-left: 2px;
              font-size: 13px;
              color: $secondary;
              cursor: pointer;
              font-family: 'icons';
            }
          }

          &:checked ~ label:after {
            content: '\E826';

          }
        }

        .form-type-radio {

          display: flex;
          align-items: center;

          input[type="radio"] {
            display: none;

            ~ label {
              font-weight: $font-weight-bold;
              width: 60%;
              cursor: pointer;
              position: relative;
              padding-left: 30px;

              &:after,
              &:before {
                content: ' ';
                background: white;
                width: 20px;
                position: absolute;
                left: 0;
                height: 20px;
                border: 1px solid darken($border-color, 10);
                border-radius: 50%;
                display: inline-block;
                cursor: pointer;
                padding-left: 2px;
                font-size: 13px;
                color: $secondary;
                font-family: 'icons';
                box-shadow: inset 0px 2px 3px rgba(black, .2);
                margin-right: 10px;
                box-sizing: border-box;
              }
            }

            &:after {
              display: None;
            }

            &:checked ~ label:after {
              background: $secondary;
              height: 10px;
              width: 10px;
              margin: 5px;
            }
          }
        }

        .form-item {
          float: none;
          width: auto;

          label {
            span {
              width: auto;
              float: none;
            }
          }

          textarea {
            height: auto;
            margin-top: 0.25rem;
            margin-bottom: 5px;
          }

          input[type="text"], input[type="number"], input[type="tel"], input[type="email"] {
            line-height: 30px;
            padding-left: 10px;
            margin-bottom: 5px;
            height: 40px;

            &::placeholder {
              color: #aaaaaa;
            }
          }
        }

        &#edit-booking-type {
          .form-radios {
            margin-top: 20px;

            .form-item {
              display: inline-block;
              width: calc(50% - 3px);
              @include media-breakpoint-down(xs) {
                width: 100%;
              }

              label {
                width: 100%;
                font-weight: normal;
                line-height: normal;
              }
            }
          }
        }


        &#edit-supplements {

          .fieldset-wrapper {
            .form-type-select,
            .form-type-checkbox {
              position: relative;
              float: none;
              width: 70%;
              display: flex;
              align-items: center;
              @include media-breakpoint-down(md) {
                width: calc(100%);
              }

              .field-suffix {
                order: 2;
                margin-right:.5rem;
                white-space: nowrap;

                @include media-breakpoint-down(xs){

                  font-size:.9em;
                }
              }

              input, select {
                order: 3;
              }

              select {
                margin: 0;
                margin-left: auto;
                width: auto;
              }

              label {
                width: 60%;
                margin: 0;
                line-height: 1.2;
                @include media-breakpoint-down(xs){
                  width:50%;
                }
              }

              input[type="checkbox"] {
                ~ label {
                  &:after {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    margin-right: 40px;
                    margin-top: -11px;
                  }
                }
              }
            }
          }
        }

        &#edit-payment,
        &#edit-details {
          div.messages {
            float: none;
          }

          #edit-date-of-birth {
            legend {
              font-size: 1rem;
              margin-bottom: 0.125rem;
              font-weight:bold;
            }
          }


          .form-type-radios {
            label {
              display: block;
              float: none;
            }

            .form-type-radio {
              width: auto;
              white-space: nowrap;
              @include media-breakpoint-down(xs) {
                margin-right: 0;
                width: 100%;
              }

              label {
                float: none;
                font-weight: normal;
                line-height: normal;
              }
            }
          }

          .form-item-agree,
          .form-item-newsletter {
            position: relative;
            margin-top: 10px;
            line-height: 1;
            @include clearfix;

            label {
              padding-left: 30px;
              position: relative;
              font-weight: normal;
              width: 100%;
              line-height: 1.5;
            }

            label:after {
              position: absolute;
              left: 0;
              top: 3px;
            }
          }

          .flex {
            display: flex;
            flex-wrap: wrap;
            justify-content:space-between;
            @include media-breakpoint-down(xs) {
              display: block;
              input {
                width: 100% !important;
              }
            }

            select {
              height: 40px;
              width: 100%;
            }

            > fieldset,
            > .form-item {
              width: calc(50% - #{$gutter/2});
              @include media-breakpoint-down(xs) {
                width: 100%;
              }
              margin-bottom: 1rem;

            }

            fieldset .fieldset-wrapper{
              .fieldset-description{
                width:100%;
              }
              margin-bottom: 0;
              display:flex;
              flex-wrap: wrap;
              justify-content: space-between;
              .form-item.form-type-select{
                flex: 3;
              }
              .form-item {
                flex: 2;
                margin-right: 10px;
                margin-bottom: 0px;
                &:nth-child(3n) {
                  margin-right: 0px;
                }

                select,
                input {
                  width: 100%;
                }
              }
            }
          }

          .agree {
            @include clearfix;
            @include media-breakpoint-down(xs) {
              margin: 30px 0;
            }

            .form-item {
              margin-top: 0;

              a {
                text-decoration: underline;
              }
            }
          }

          .submit {
            padding-top:2em;
            margin: 0;
            @include media-breakpoint-up(xl) {
              display: flex;
              flex-wrap: wrap;
              .reliability-icons {
                margin-left: auto;
                > * {
                  margin-left: 1rem;

                  &:first-child {
                    margin-left: 0;
                  }
                }
              }
            }
            @include media-breakpoint-down(lg) {
              input {
                width: 100%;
                justify-content: space-around;
              }
              .reliability-icons {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                > * {
                  margin: 1rem;
                }
                align-items: center;
              }
            }
          }
        }
      }
    }
  }
}

.col.booking {
  @include media-breakpoint-up(md) {
    max-width: calc(100% - #{$booking-step-sidebar});
  }
}

.overview-col {
  @include media-breakpoint-up(md) {
    flex: 0 0 $booking-step-sidebar;
  }
}

.overview-wrapper {
  @include media-breakpoint-up(md) {
    .sticky-overview & {
      width: calc(#{$booking-step-sidebar} - 30px);
      position: fixed;
      top: 2rem;
      height: 0;
    }
  }

  h3 {
    border-top: none !important;
  }

  .overview {
    > .card-body {
      border-bottom: 1px solid $booking-funnel-line-color;
      &:last-child {
        border-bottom: none;
      }
    }

    font-size: .9em;


    .booking-details {
      > div {
        padding-left: 40px;
        display: block;
        position: relative;
        line-height: 35px;

        &:before {
          width: 30px;
          text-align: center;
          position: absolute;
          font-size: 1.4rem;
          top: 0;
          left: $booking-step-margin;
          margin-left: -20px;
          font-family: icons;
        }

        &.persons {
          &:before {
            content: '\E818';
          }
        }

        &.dates {
          &:before {
            font-size: 1.1rem;
            content: '\F133';
          }

          > div {
            display: inline-block;
          }

          .separator {
            margin: 0 10px;

            &:before {
              content: '\E836';
              font-family: 'icons';
            }
          }
        }

        &.nights {
          &:before {
            font-size: 1.2rem;
            content: '\f186';
          }
        }
      }
    }

    .costs {
      .mobile-summary {
        display: none;
      }

      #cost-overview {

        h3 {
          margin: 1rem 0;
        }
      }
    }
  }

  .helpdesk {
    margin-top: $booking-step-margin;
    font-size: 1.2rem;
    display: flex;
    align-items: center;

    .left {
      width: 60px;
      margin-right: 20px;

      img {
        border-radius: 50%;
        width: 100%;
        height: auto;
        box-shadow: 0px 2px 3px rgba(black, .2);
      }
    }

    .main {
      font-size: .9em;

      p {
        margin: 0;
      }
    }

    .phone {
      position: relative;
      display: inline-block;
      padding-left: 20px;

      p,
      div {
        margin: 0;
        display: inline-block;
      }

      &:before {
        content: '\E802';
        font-family: 'icons';
        position: absolute;
        left: 0;
      }
    }
  }
}

.pre-booking-instructions,
.post-booking-instructions {
  margin: $booking-step-margin 0;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      margin: 0;
      padding: 0;
      padding-left: 40px;

      &:before {
        content: '\e826';
        font-size: 1.3em;
        margin-left: -30px;
        margin-right: 10px;
        color: green;
        font-family: 'icons';
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .overview-wrapper,
  .booking {
    width: 100%;
    float: none;
  }
  .overview-wrapper .overview {
    width: 100%;
  }
}

.page-book {
  .mobile-menu-toggle{
    display:none !important;
  }
  .col.reliability {
    display: flex;
    justify-content: flex-end;
    align-self: center;
    > * {
      align-items:center;
      display:flex;
      .reliability-icons{
        margin-left:1rem;
      }
    }
  }

  #cost-overview {
    .inclusive label {
      padding-left: 5px;

      &:before {
        font-family: 'icons';
        content: '\E826';
        display: inline-block;
        background: green;
        font-size: 10px;
        color: white;
        border-radius: 50%;
        padding: 2px;
        margin-right: 5px;
        vertical-align: top;
      }
    }

    .discounts {
      color: $secondary;
      font-weight: $font-weight-bold;
    }

    label {
      float: left;
      width: calc(100% - 110px);
      text-align: left;
      padding: 0 5px 10px 0;
      font-weight: normal;

      &.no-span {
        width: 100%;
      }
    }

    .total-cost label {
      font-size: 18px;
    }

    div {
      clear: left;
      float: left;
      width: 100%;
      text-align: left;
      line-height: 1;

      .currency {
        float: left;
        padding-right: 10px;
      }

      &.subtotal,
      &.total-cost {
        border-top: 1px solid $booking-funnel-line-color;
        font-weight: $font-weight-bold;
        font-size: 1.2rem;
        padding-top: 10px;

        label {
          font-weight: $font-weight-bold;
        }
      }

      &.total-cost {
        margin-bottom: 20px;
      }

      &.subtotal {
        font-size: 1rem;
        margin-bottom: 15px;
        padding-top: 10px;
        margin-top: 0;
        border-top: 1px solid $booking-funnel-line-color;
      }
    }
  }

}
