.ajax-progress-throbber {
  position:absolute;
  width:100%;
  height:100%;
  background:rgba(white, .6);
  left:0;
  top:0;
  .throbber {
    @include spinner;
  }
}