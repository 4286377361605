#booking-widget .calendar .spinner-wrapper {
  margin: 1rem 0;
  text-align: center;
  padding-right: 0;
}
.cal-month {
  background: $white;
  border-right: 1px solid $gray-200;
  border-bottom: 1px solid $gray-200;
}

.cal-month-header {
  border-top: 1px solid $gray-200;
  border-left: 1px solid $gray-200;
  font-size: 1.125rem;
  display: flex;
  justify-content: center;
  padding: 1rem 0 0.5rem 0;
}

.cal-day-names {
  border-left: 1px solid $gray-200;
}

.cal-week, .cal-day-names {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.cal-day, .cal-day-name {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;
  line-height: 2rem;
}

.cal-day-name {
  border-top: 1px solid $white;
  border-left: 1px solid $white;
}

.cal-day {
  color: $white;
  background-color: $legenda-available-color;
  border-top: 1px solid $gray-200;
  border-left: 1px solid $gray-200;
  &.blocked {
    background-color: $legenda-blocked-color;
  }
  &.no-date {
    background: $white;
  }
  &.selectable {
    cursor: pointer;
  }
  &.arrival {
    background-image: url(/sites/all/themes/flean_base_2019/images/cal-arrival.png);
  }
  &.departure {
    background-image: url(/sites/all/themes/flean_base_2019/images/cal-departure.png);
  }
  &.arrival, &.departure {
    background-color: $legenda-available-color;
    background-size: 100% 100%;
  }
  &.selected {
    background-color: $primary;
  }
}

.calendar .row {
  position: relative;
}

.cal-prev, .cal-next {
  position: absolute;
  z-index: 1;
  top: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  svg {
    fill: $link-color;
    height: 1.5rem;
  }
}
.cal-prev {
  left: 1.675rem;
  .cal-row & {
    left: 0.675rem;
  }
}
.cal-next {
  right: 1.675rem;
  .cal-row & {
    right: 0.675rem;
  }
}

.legenda-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  div {
    line-height: 2rem;
    color: $gray-700;
    @include media-breakpoint-down(sm) {
      font-size: .825rem;
    }
  }
  .legenda {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    &.available {
      background: $legenda-available-color;
    }
    &.blocked {
      background: $legenda-blocked-color;
      margin-left: 2rem;
    }
  }
}
