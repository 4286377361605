.image-slide {
  .image {
    font-size: 0;
    @include card;
    .slick-prev,
    .slick-next {
      width: 5rem;
      svg {
        fill: $gray-300;
        height: 3rem;
        margin-top: -1.5rem;
        filter: drop-shadow( 0 0 2px rgba(0, 0, 0, .75));
      }
      &:hover {
        svg {
          fill: $white;
          filter: drop-shadow( 0 0 3px rgba(0, 0, 0, .95));
        }
        
      }
    }
    .slick-prev {
      left: 0;
      svg {
        left: 1rem;
      }
    }
    .slick-next {
      right: 0;
      svg {
        right: 1rem;
      }
    }
  }

  .slider-thumbs-wrapper {
    position: relative;
  //  padding: 0 2.5rem;
  }

  .slider-thumbs {
    height: 48px;
    overflow: hidden;
    margin-top: 0.5rem;
    &.slick-initialized {
      overflow: inherit;
    }
    a {
      margin-left: 0.5rem;
      &:first-child {
        margin-left: 0;
      }
      opacity: 0.7;
      &:hover, &.active {
        opacity: 1;
      }
    }
  }

  .thumbs-prev,
  .thumbs-next {
    width: 2rem;
    border: 1px solid $gray-500;
    svg {
      fill: $gray-500;
      top: 50%;
      margin-top: -1rem;
      height: 2rem;
    }
    &:hover svg {
      fill: $gray-100;
    }
  }
  .thumbs-prev {
    left: 0;
    svg {
      left: 0.25rem;
    }
  }
  .thumbs-next {
    right: 0;
    svg {
      right: 0.25rem;
    }
  }

}
