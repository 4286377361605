/* Required Bootstrap files */
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

/* Optional Bootstrap files */
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
//@import "../bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/modal";
@import "../../node_modules/bootstrap/scss/close";
@import "../../node_modules/bootstrap/scss/nav";
//@import "../bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/card";
//@import "../bootstrap/scss/breadcrumb";
//@import "../bootstrap/scss/pagination";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/alert";
@import "../../node_modules/bootstrap/scss/spinners";
@import "../../node_modules/bootstrap/scss/utilities";
