#block-helper-similar-holiday-homes{
  .node-type-holiday-home &{
 //   background:white;
  }
}
.node-complex,
.node-holiday-home,
.node-holiday-home-xl {

  .title.with-contact-anchor {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .code {
      display: flex;
      flex-basis: 100%;
    }
    h1 {
      max-width: 75%;
    }
  }

  &.full {
    .inactive {
      background: $secondary;
      text-align: center;
      padding: 1rem;
      margin-bottom: 1rem;
      border-radius: $border-radius;

      p {
        margin: 0;
        color: white;

        a {
          color: white;
          text-decoration: underline;
        }
      }
    }

    .row.gallery {
      #image-gallery {
        min-height: 5rem;
        width: 60%;
        flex-basis: auto;
        @include media-breakpoint-between(md, lg) {
          width: 50%;
        }
        @include media-breakpoint-down(md) {
          width: 100%;
          padding: $gutter/2;
          .image-gallery-thumbnails-wrapper{
            padding: 0 .5rem;
          }
        }
        @include media-breakpoint-down(sm){
          padding: 0;
        }
        .gallery-fullscreen & {
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      .summary {
        width: 40%;
        flex-basis: auto;
        @include media-breakpoint-between(md, lg) {
          width: 50%;
        }

        @include media-breakpoint-down(md) {
          width: 100%;
          .side-block{
            padding: 2rem 0 !important;
          }
        }

        .side-block {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 2.5rem;
          .code {
            color: $gray-600;
            font-size: .75rem;
          }

          h1 {
            @include media-breakpoint-up(md) {
              font-size: 1.75rem;
            }
            margin-bottom: .25rem;
          }

          h3.accommodation-type {
            color: $primary;
            font-size: .875rem;
          }

          .teaser {
            font-size: .875rem;
            margin-top: auto;
            margin-bottom: auto;
            @include media-breakpoint-between(md, lg) {
              display: none;
            }
          }

          .average {
            display: flex;
            align-items: center;

            .title {
              color: $stars-color;
              font-weight: $font-weight-bold;
              margin-right: .5rem;
            }

            .number {
              font-weight: $font-weight-bold;
              color: white;
              font-size: .875rem;
              border-radius: $border-radius;
              padding: .25rem;
              line-height: 1;
              margin-right: 1rem;
              @include tooltip(5px, left, $primary);
            }

            .link-to-all a {

              font-size: .85em;
              color: $primary;
              text-decoration: underline;
            }
            .contact-anchor {
              margin-left: auto;
            }
          }

          .review-slider {

            margin: 1rem 0;
            padding: 1rem 0;
            border-top: 1px solid $border-color;
            border-bottom: 1px solid $border-color;
            display: flex;
            align-items: center;
            @include media-breakpoint-only(lg) {
              height: 0;
              overflow: hidden;
              padding: 0;
              border-top: none;
              border-bottom: none;
            }
            @include media-breakpoint-down(md) {
              padding-left: 1rem;
              padding-right: 1rem;
              margin:1rem;
            }
          }

          .go-to-accommodations,
          #price-info {
            margin-top: 1rem;

            a {
              width: 100%;
              @include call_to_action;
              font-weight:normal;
              @include media-breakpoint-down(md) {
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
    #description{
      .full-description{

        .full-description-toggle {
          display:none;
        }
        position:relative;
        .hide-full-description & {
          height: 200px;
          overflow: hidden;

          &:before {
            background: linear-gradient(rgba($body-bg, 0), rgba($body-bg,.9), rgba($body-bg, 1));
            position: absolute;
            content: ' ';
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
          }

          .full-description-toggle {
            display:block;
            position: absolute;
            bottom: 0;
          }
        }
      }
    }
  }
}
