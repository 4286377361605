/* Breadcrumb  */
.breadcrumb-wrapper {
  position: relative;
  margin: 1rem 0 1rem 0;
  //font-family: $headings-font-family;
  ul.breadcrumb {
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      list-style: none;
      font-size: 0.875rem;
      color: $breadcrumb-text-color;
      a {
        color:$breadcrumb-text-color;
       // color:$secondary;
        text-decoration: underline;
        &:hover{
          color:$secondary;
        }
      }
    }
  }
  .breadcrumb > *:before {
    content: ">";
    margin: 0 7px;
    position: relative;
    top: 0;
  }
  .breadcrumb > *:first-child:before {
    content: " ";
    margin: 0;
  }
}
