.address {
  position: relative;
  margin-left: 1.5rem;
  svg {
    position: absolute;
    left: -1.5rem;
    top: 0.25rem;
  }
}
.street, .zip-city {
  > div {
    display: inline;
  }
}
