
.sod_select {

  font-size:.9em;
  &:before {
    display: none;
  }

  .sod_label {
    display:flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    white-space:nowrap;
    &:after{
      margin-left:.5rem;
    }
    @include icon('down-open', 'after');
  }
}
