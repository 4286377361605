@mixin teaser-discounts {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  > .inner {
    left: 0px;
    transform: rotate(-45deg);
    position: relative;
    height: 300px;
    > div {
      text-align: center;
      width: 300px;
      background: rgba($primary, .9);
      font-weight: $font-weight-bold;
      text-transform: uppercase;
      color: white;
      display: block;
      font-size: .8em;
      padding: 5px 10px;
      box-shadow: rgba(black, .4) 0 0 5px;
      margin-bottom: 5px;
    }
  }
}
