#block-helper-newsletter-sign-up {
  margin-top:4rem;
  .newsletter-block-without-image {
    > div {
      max-width:600px;
      margin: 0 auto;

    }
    padding:2rem 1rem;
    .btn {
      margin-top:1.6em;
    }
  }
  .newsletter-block-without-image,
  .newsletter-block {
    .info {
      margin-top:.5rem;
      font-size:.8rem;
    }
    .form-inline {
      margin-top: 0 !important;
      label {
        justify-content: start;
      }
      > div > div {
        display:flex;
        align-items: center;

        @include media-breakpoint-down(xs) {
          display: block;
        }
        .form-item {
          margin-bottom:0;
          margin-right: 0.5rem;
        }
        input.form-text {
          width:100%;
        }
      }
    }
    @include card;
    overflow: hidden;

    input[type="submit"] {
      @include call_to_action;
    }
  }

  form._form {
    padding: 0 !important;
    margin-top: 0;

    label {
      display: none !important;
    }


    ._button-wrapper {
      margin-top: 0;

      button {
        @include call_to_action($call_to_action_bg, !important);
      }
    }

    ._error._below {
      position: relative;
      margin-top: .5rem;
      white-space: nowrap;
      width: 10px;

      ._error-arrow {
        display: none;
      }
    }
  }

  .image {
    @include media-breakpoint-down(md){
      display:none;
    }
  }

  .content {
    padding: $gutter/2 $gutter;
    display: flex;
    flex-direction: column;
    justify-content: center;
    label {
      display: none;
    }

    h3, h4, p, div {
      //color: $white;
    }

    .info {
      font-size: 80%;
      opacity: 0.9;
    }


    .form-text {
      margin-top: 0;
      @include media-breakpoint-down(lg) {
        width: 400px;
      }
    }
    @include media-breakpoint-down(md) {
      width:100%;
      button,
      input{
        width:100% !important;
        margin: 0 0 0.5rem 0;
      }
    }

    form > div {
      display: flex;
      @include media-breakpoint-down(md) {
        display:block;

      }
      .form-item {
        margin-bottom: 0;

        input[type="email"] {
          width: 100%;
        }
      }
    }
  }

  #helper-newsletter-sign-up-form {
    .btn-primary {
      max-width: 128px;

      @include media-breakpoint-down(md) {
        margin: 0 0 0.5rem 0;
      }
      @include media-breakpoint-down(xs) {
        max-width: none;
      }
    }

    #newsletter_sign_up_form_elements_wrap > div {
      .form-item {
        margin-right: 0;
      }

      input.form-text {
        margin-bottom: 0.5rem;
      }

      @include media-breakpoint-down(sm) {
        display: block;

        .btn-primary {
          max-width: none;
        }
      }
    }
  }
}
