

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
  .no-sidebars #main {
    &.container #block-system-main {
      @include media-breakpoint-only(xl) {
        width: 1144px;
      }
      @include media-breakpoint-only(lg) {
        width: 964px;
      }
      @include media-breakpoint-only(md) {
        width: 712px;
      }
    }
    .container {
      @include media-breakpoint-only(xl) {
        width: 1176px;
      }
      @include media-breakpoint-only(lg) {
        width: 996px;
      }
      @include media-breakpoint-only(md) {
        width: 774px;
      }
    }
  }
  .col-lg-9 #block-helper-search-holiday-homes {
    @include media-breakpoint-only(xl) {
      width: 850px;
    }
    @include media-breakpoint-only(lg) {
      width: 715px;
    }
    @include media-breakpoint-only(md) {
      width: 712px;
    }
  }
  #header > .header .language-menu .language-toggle svg:first-child,
  #header > .header .language-menu .dropdown-menu a svg {
    width: 21.5px;
  }
  #header > .header .language-menu .language-toggle svg:last-child {
    width: 14px;
  }
  .h-100 {
    display: block !important;
  }
  .features ul li svg {
    height: 1rem;
  }
  ul.trust-features svg {
    width: 1.5rem;
  }
  #features li svg, #layout li svg, #area li svg {
    width: 0.5rem;
  }
  #footer svg {
    width: 0.5rem;
  }
  #footer .follow svg {
    width: 1rem;
  }
  .sod_select .sod_label::after, #sidebar-first .facet.block-helper-search h3::after {
    float: right;
  }
  .payment-icons img {
    height: 40px;
    &.i-deal {
      width: 45px;
    }
    &.master-card {
      width: 40px;
    }
    &.visa, &.paypal {
      width: 80px;
    }
  }
  .list .row .price-wrapper {
    position: static;
  }
}
