// Mobile only
@include media-breakpoint-down(sm) {
  h1 {
    font-size: 1.5rem;
    font-weight: $font-weight-bold;
  }
}

@media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
  .container,
  .row > .col,
  .row > [class*="col-"] {
    padding-right: $gutter-mobile;
    padding-left: $gutter-mobile;
  }
  .row{
    margin-left: -$gutter-mobile;
    margin-right:-$gutter-mobile;
  }
}


#block-helper-last-minutes,
#block-helper-early_birds,
#block-helper-long_stays {
  .title {
    display: flex;
    align-items: center;
  }

  svg {
    height: 1.875rem;
    width: 1.875rem;
    fill: $headings-color;
  }

  h2 {
    margin-left: 0.5rem;
    line-height: 1;
    margin-bottom: 0;
  }

  @include media-breakpoint-only(md) {
    .holiday-home-teaser:nth-child(3) {
    //  display: none;
    }
  }
}

.page-blocks {
  .show-more {
    text-align: center;
    @include media-breakpoint-down(sm) {
      margin-top: 1rem;
    }
  }
}

#sidebar-first {
  margin-top: 0.25rem;
  @include media-breakpoint-only(lg) {
    .sod_select .sod_label, li, li a {
      font-size: 0.875rem;
    }
  }
}

.holiday-homes.list {
  margin-bottom: 1.5rem;
}

/* Custom column widths min overview page */
$image-sizes: (
  xl: 32%,
  lg: 32%,
  md: 32%,
);
$price-sizes: (
  xl: 24%,
  lg: 24%,
  md: 32%,
);
$content-sizes: (
  xl: 100% - map-get($image-sizes, 'xl') - map-get($price-sizes, 'xl'),
  lg: 100% - map-get($image-sizes, 'lg') - map-get($price-sizes, 'lg'),
  md: 100% - map-get($image-sizes, 'md') - map-get($price-sizes, 'md'),
);

@include grid-list($image-sizes, 'image');
@include grid-list($content-sizes, 'content');
@include grid-list($price-sizes, 'price-wrapper');
@include grid-list($price-sizes, 'show');

.list {
  .row {
    @include media-breakpoint-down(sm){
      flex-direction:column;
      margin-left: 0;
      margin-right:0;
    }
    .image {
      border-top-left-radius: $border-radius;
      @include media-breakpoint-down(sm) {
        padding-top: 75%;
        border-top-right-radius: $border-radius;
      }
      @include media-breakpoint-up(md) {
        border-bottom-left-radius: $border-radius;
      }
    }
  }
}

// Extra override for general search result.
.view-search {
  $image-sizes: (
    xl: 33.3333333333%,
    lg: 33.3333333333%,
    md: 33.3333333333%,
  );
  $price-sizes: (
    xl: 24%,
    lg: 24%,
    md: 33.3333333333%,
  );
  $content-sizes: (
    xl: 100% - map-get($image-sizes, 'xl') - map-get($price-sizes, 'xl'),
    lg: 100% - map-get($image-sizes, 'lg') - map-get($price-sizes, 'lg'),
    md: 100% - map-get($image-sizes, 'md') - map-get($price-sizes, 'md'),
  );

  @include grid-list($image-sizes, 'image');
  @include grid-list($content-sizes, 'content');
  @include grid-list($price-sizes, 'show');
}

.node-type-overview-page, .node-type-offers-page, .node-type-complexes-page {
  #block-system-main .field-body, #block-helper-recently-watched-3 {
    margin-bottom: 3rem;
  }
}
