#helper-search-holiday-homes-form .form-type-textfield {
  position: relative;
  @include icon('down-open', 'after');
  &:after {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    .display-row & {
      right: 1.25rem;
    }
    line-height: 1;
    color: $body-color;
    pointer-events: none;
    .not-front & {
      top: calc(0.5rem + 1px);
    }
  }
  .form-control:disabled, .form-control[readonly] {
    background: $white;
    //border: 1px solid $border-color;
  }
  .form-control {
    line-height: 1;
    transition: none;
    height: 2.5rem;
    padding: 0.75rem;
    .not-front & {
      height: calc(2rem + 2px);
      padding: 0.5rem 0.75rem;
    }
  }
  .form-text {
    margin-top: 0;
    line-height: 1;
    border: none;
    .not-front & {
      border: 1px solid $border-color;
    }
    &::placeholder {
      color: $body-color;
      line-height: 1;
    }
  }
}
#helper-search-holiday-homes-form .form-item-check-out {
  .sod_select .sod_label:after {
    display: none;
  }
}
.ui-datepicker .ui-datepicker-next:not(.ui-state-disabled) span {
  @include icon('angle-right', 'after');
  &::after {
    position: absolute;
    text-indent: 0;
    font-size: 1.75rem;
    right: 0.5rem;
    top: 1px;
  }
}

.ui-datepicker .ui-datepicker-prev:not(.ui-state-disabled) span {
  @include icon('angle-left', 'after');
  &::after {
    position: absolute;
    text-indent: 0;
    font-size: 1.75rem;
    left: 0.5rem;
    top: 1px;
  }
}
