@mixin card($hoverable: false){
  border-radius:$border-radius;
  box-shadow:rgba(black,.2) 0 1px  3px;
  background: $white;
  border:none;
  @if $hoverable{
    &:hover {
      text-decoration: none;
      background: $gray-100;
      box-shadow:rgba(black,.4) 0 1px  4px;
    }
  }
}