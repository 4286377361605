/* Flean variables */
$base_theme_path : '/sites/all/themes/flean_base_2019';
$legenda-available-color: #39b54a;
$legenda-blocked-color: #bbb;
$advantage-color: #598527;

/* Grid */
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1020px,
  xl: 1200px
);

$container-max-widths: (
  md: 744px,
  lg: 996px,
  xl: 1176px
);
$gutter:2rem;
$grid-gutter-width: 2rem;
$half-gutter-width: ($grid-gutter-width / 2);

$gutter-mobile: .75rem !default;

/* Colors */
$succes: $advantage-color;

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$body-bg: mix($gray-100, $gray-200) !default;

$primary: #007bff !default;
$secondary: #fd6400 !default;
$tertiary: $gray-600 !default;
$stars-color: #ffb62c !default;

$font-family-base: 'Helvetica', non-serif !default;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
);

$grey-color: $gray-600 !default;
$link-color: $secondary !default;
$border-color: $gray-300 !default;
$border-radius: .25rem !default;

/* Navbar */
$navbar-dark-color: white !default;
$navbar-dark-hover-color: rgba($white, 0.75) !default;
$navbar-dark-active-color: rgba($white, 0.75) !default;
$nav-link-padding-x: 0.5rem !default;
$search-input-width: 100px !default;
$headings-font-family: sans-serif !default;

/* Tabs */
$nav-tabs-link-color: $gray-600 !default;
$nav-tabs-link-active-color: $gray-800 !default;

/* Dropdown */
$dropdown-link-hover-bg: $border-color !default;

/* Types */
$body-color: #212529 !default;
$headings-color: $primary !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;
$h3-font-size: 1.5rem;
$h4-font-size: 1rem;
$h5-font-size: 1rem;
$h6-font-size: 1rem;

/* Border radius */
$border-radius: .25rem !default;

/* Height of slideshow on top of page */
$slideshow_wide_height: 400px;


/* Flean variables */
$search-home-bg-color: $primary !default;
$search-overview-bg-color: $primary !default;
$legenda-available-arrival-date-color: $secondary !default;
$legenda-selected-arrival-date-color: $tertiary !default;

$booking-step-sidebar: 440px !default;
$booking-step-margin: 1rem !default;
$booking-step-margin-mobile: 7px !default;
$booking-funnel-line-color: rgba(black, .1)  !default;
$step-background: #d6e4f3  !default;;
$header-bg-color: $white !default;
$mobile-menu-background:$gray-800 !default;
$mobile-menu-color: white !default;
$mobile-menu-border-color:darken($mobile-menu-background,10);
$mobile-header-background: $primary !default;
$mobile-header-color: white!default;
$static_header_area_width:37% !default;
$static_header_area_tips_background_color: rgba(white, .7) !default;
$static_header_area_form_background_color: $search-home-bg-color !default;
$top_strip_color: $primary !default;
$top_strip_text_color: white !default;
$breadcrumb-text-color: $gray-500 !default;
$footer-background-color: $primary !default;
$footer-text-color: white !default;
$footer-text-color-header: white !default;
$discount-color: green !default;
$search-filter-tag-color: $secondary !default;
$bottom-footer-bg-color: darken($footer-background-color,4) !default;
$bottom-footer-text-color: $footer-text-color;
$footer-border-top: none !default;
$call_to_action_bg: $secondary !default;
$call_to_action_text: white !default;
$spinner-color:$secondary !default;
$navbar-font-weight: $font-weight-normal !default;
$navbar-font-family: $headings_font-family !default;
$user-menu-text-color: $gray-600 !default;
$headings-color-detail: $primary !default;
$body-font-family: inherit !default;
$body-font-size: inherit !default;
$body-line-height: inherit !default;
$mega-menu-bg-color: $body-bg !default;
$mega-menu-text-color: $gray-800 !default;
$static-header-heading-font-family: $headings-font-family !default;
$static-header-heading-font-weight: normal !default;
$static_header_area_form_text_color: white !default;
$static_header_area_tips_text_color: $body-color !default;

/* Hamburger variables */
$hamburger-layer-width         : 1.4rem !default;
$hamburger-layer-height        : 3px !default;
$hamburger-layer-spacing       : 5px !default;
$hamburger-padding-x:0  !default;
$hamburger-padding-y:0  !default;
$hamburger-layer-color:$mobile-header-color !default;
