
.holiday-home-teaser, .destinations-blocks {
  a .card {
    @include card(true);
  }
  a:hover {
    text-decoration: none;
  }
  img {
    width: 100%;
  }
  .card {
    .card-body {
      padding-bottom:0;
      @include media-breakpoint-down(sm){
        padding:$gutter-mobile $gutter-mobile 0 $gutter-mobile;
      }
      .highest-average-field {
        margin-bottom: .375rem;
        .label {
          color: $stars-color;
          margin-right: .5rem;
          font-size: .875rem;
        }
        .value {
          font-weight: $font-weight-bold;
          color: $white;
          font-size: .875rem;
          border-radius: $border-radius;
          padding: .25rem;
          line-height: 1;
          margin-right: 1rem;
          @include tooltip(5px, left, $primary);
        }

      }
      .price-info{
        display:flex;
        flex-direction:column;
        .price-context{
          display:contents;
          .price-context-info {
            order: 1;
            color: $gray-600;
          }
        }
        .discount{
          order:4;
          margin-top: .25rem;
          font-size: .875rem;
        }
        .price-values {
          order:2;
          .prefix {
            font-size: .875rem;
          }
        }
      }
      h4 {
        font-size: 1.25rem;
        .city {
          display: inline;
          &:before {
            content: ' - ';
          }
        }
      }
    }

    .card-footer {
      background: none;
      border-top: none;
      padding-bottom: $card-spacer-x;
      @include media-breakpoint-down(sm){
        padding:$gutter-mobile;
      }
    }
  }
}

.holiday-home-teaser,
.node-teaser {
  .discounts {
    @include teaser-discounts;
  }
}

.list {
  .row {
    flex-wrap: nowrap;
    margin-bottom: 1rem;
    @include card(true);
    position: relative;

    .content {
      margin: $half-gutter-width 0;
      padding: $half-gutter-width $gutter;
      @include media-breakpoint-down(md) {
        margin: 0;

        padding: $gutter-mobile;
      }
      display: flex;
      flex-direction: column;
      justify-content: center;

      .features {
        display: flex;
        justify-content: space-between;
        padding: 0;
        margin: 0;
        margin-top: .75rem;

        li {
          color: $grey-color;
          list-style: none;
          margin: 0;
          padding: 0;
          line-height: 1;
          font-size: 0.875rem;
        }
      }

      .title {
        position: relative;
        font-size: 1.1rem;
        font-weight: $font-weight-bold;
        color: $headings-color;
        margin-top: -0.25rem;
        display: inline;
        line-height: 1.125;

        .type {
          display: inline;

          &:after {
            content: ' - ';
          }
        }

        @include media-breakpoint-down(md) {
          .city {
            display: inline;

            &:before {
              content: ' - ';
            }
          }
        }
        @include media-breakpoint-up(lg) {
          .city {
            color: $grey-color;
            font-size: 0.875rem;
            font-weight: normal;
          }
        }
      }

      .rating {
        display: flex;
        align-items: center;
        margin-top: .75rem;
        margin-bottom:.75rem;
        .average {
          font-size: 0.875rem;
          padding: 0.375rem;
          line-height: 1;
          background: $primary;
          color: $white;
          border-radius: $border-radius;
          margin-right: .5rem;
        }

        .total-ratings {
          color: $grey-color;
          font-size: 0.875rem;
          font-weight: normal;
        }
      }

      .teaser {
        color: $body-color;
        font-size: .875rem;
      }
    }

    .price-wrapper,
    .show {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;
      margin: $half-gutter-width 0;
      padding: $half-gutter-width $gutter;
      border-left: 1px solid $border-color;
      position: initial;
      @include media-breakpoint-down(sm) {
        border-top: 1px solid $border-color;
        border-left: none;
        margin: 0 $gutter-mobile $gutter-mobile $gutter-mobile;
        padding: $gutter-mobile 0 0 0;
        width: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: nowrap;
        align-items: center;
        .discounts {
          display: none;
        }
        .date,
        .price-context-info{
          font-size:.7em;
          color:$gray-600;
        }
        .price-context {
          order: 0;
          white-space: normal;
          width: 23%;

          .discount {
            display: none;
          }
        }
        .price-values {
          order: 1;
          margin-left: .375rem;
          margin-right: .375rem;

          .price {
            white-space: nowrap;
          }
          .original-price{
            font-size: .5rem;
          }

          .prefix {
            font-size: .625rem;
            font-weight: normal;
            display: block;
          }
        }
        .btn {
          order: 2;
          margin-top: 0 !important;
          white-space: nowrap;
        }
        .price-info {
          line-height: 1;
          display: contents;

          .price {
            font-size: 1rem;
          }
        }
      }

      .btn {
        @include call_to_action;
        white-space: nowrap;
      }

    }
  }
}

.destinations-blocks {
  .content p {
    color: $body-color;
    span.read-more {
      color: $link-color;
    }
  }
}

.row > .holiday-home-teaser {
  @include media-breakpoint-down(md) {
    margin-bottom: 1rem;
  }
}

.accommodations, .holiday-homes.photos, .complexes.photos, #accommodations .row {
  > div {
    margin-bottom: 3rem;
  }
}
