.reliability-icons,
.payment-icons{
  display: flex;
  align-items: center;
  img{
    width: auto;
    height: auto;
    max-width: 80px;
    max-height: 40px;
    object-fit: contain;
    margin: .5rem;
    min-width: 40px;
    min-height:40px;
  }
}

.reliability-icons {
  &.reliability-icon-location-main-menu-search-toggle{
    margin-left:auto !important;
    img{
      max-height:30px;
      min-height:30px;
    }
  }
  &.reliability-icon-location-contact-block {
    margin-top: 1rem;

    img {
      max-width: 100px;
    }
  }
  &.reliability-icon-location-above-footer{
   margin-top:4rem;
    display:flex;
    justify-content: center;
    align-items: center;
  }
}

