.slider-wrapper, .header-image-wrapper {
  position: relative;
  @include media-breakpoint-down(md) {
    background: $search-home-bg-color;
    padding: ($grid-gutter-width) 0;
    .header-image {
      .node-type-home-page & {
        display: none;
      }
    }
  }
}

.header-image-wrapper.display-column,
.slider-wrapper {
  @include media-breakpoint-up(lg) {
    #search-form {
      position: absolute;
      height: 100%;
      display: flex;
      align-items: center;
      width: 100%;
      top: 0;
    }
  }
}

.slider-wrapper {
  @include media-breakpoint-up(lg) {
    padding-bottom: $slideshow_wide_height;
  }
}

.header-image { // Only one image.
  width: 100%;
  height: $slideshow_wide_height;
  padding-bottom: $slideshow_wide_height;

  div {
    padding-bottom: $slideshow_wide_height;
  }

  @include media-breakpoint-down(md) {
    height: 0;
    padding-bottom: 25%;
    margin-bottom: 1rem;
    div {
      padding-bottom: 25%;
    }
  }
}

.header-image-mobile > div > div, .header-image-mobile > div > a > div { // Header image on mobile frontpage.
  padding-bottom: 50%;
  position: relative;

  .usp-wrapper {
    display: block !important;
    position: absolute;
    top: 1rem;
    left: 0;
    width: 100%;

    .usp-inner {
      text-align: center;
    }

    h3, p {
      color: $white;
      text-shadow: rgba(black, .4) 1px 1px 4px;
      margin-bottom: 0.25rem;
    }

    h3 {
      line-height: 1;
    }
  }
}

.slick-dots {
  bottom: 0;
  margin: 0;
}

#slideshow {
  position: absolute;

  &.single-image {
    img {
      max-width: 100%;
    }
  }

  @include media-breakpoint-down(md) {
    display: none;
  }
  float: left;
  width: 100%;
  height: 100%;

  > div{
    height:100%;
  }
  .slick-slide {
    height: $slideshow_wide_height;
    position: relative;
    width: 100%;

    > div > div {
      height: $slideshow_wide_height;
    }
  }
}

.usp-wrapper {
  display: none !important;
  @include media-breakpoint-up(lg) {
    display: flex !important;
    width: 100%;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    align-items: center;
    .usp-inner {
      position: relative;

      .usp {
        display:flex;
        flex-direction: column;
        justify-content: center;
        .display-column & {
          margin-left: calc(60% + 2rem);
          @include media-breakpoint-up(xl) {
            margin-left: calc(50% + 2rem);
          }
        }

        .display-row & {
          text-align: center;
        }

        text-shadow: rgba(black, .7) 1px 1px 4px;
        font-size: 1.25rem;
        @include media-breakpoint-only(lg) {
          font-size: 1rem;
        }

        h3 {
          font-size: 2.5rem;
          text-shadow: rgba(black, .4) 1px 1px 4px;
        }

        right: 0;

        h3, p {
          color: #fff;
        }
      }
    }
  }
}

.front {
  #search-form {
    #block-helper-event-helper-event-search-form {
      z-index: 99;
      padding: 1rem;
      max-width:800px;
      margin: 0 auto;
h2{
  font-size:1.5rem;
  margin-bottom:1rem;
}
      .buttons {
        display: none;
        > * {
          flex: 1;

          input {
            height: 3rem;
            background: $secondary;
          }

          margin: 1rem;
        }
      }

      .custom {

        @include card();
        padding:1rem;
        display: flex;
        justify-content: space-between;
        > input {
          height: 3rem;
        }

        > * {
          width: calc(100% / 3 - 1rem);
        }

        .form-item {
          input {
            margin: 0;
            height: 3rem;
          }

          margin-bottom: 0;

          .description,
          label {
            display: none;
          }
        }
      }
    }
  }
}
