@mixin call_to_action($background: $call_to_action_bg, $important : null) {
  background: $background $important;
  color: $call_to_action_text $important;
  border: none $important;
  font-weight: $font-weight-bold $important;
  border-radius: $border-radius $important;
  padding: $btn-padding-y $btn-padding-x;
  white-space: nowrap;
  &:hover {
    background: darken($background, 5) $important;
    color: $call_to_action_text $important;
    text-decoration: none $important;
  }
}
