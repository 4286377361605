#occupancy-rate {
  .links {
    background-color: $gray-200;
    padding: 1rem 2rem;
    &:first-child {
      background-color: $white;
    }
    a {
      font-weight: bold;
      color: $gray-700;
      &:first-child {
        margin-right: 1rem;
      }
      text-decoration: underline;
    }
  }
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .quiet {
    background-color: yellow;
  }
  .reasonable {
    background-color: orange;
  }
  .good {
    background-color: lightgreen;
  }
  .high {
    background-color: darkgreen;
  }
  .very-high {
    background-color: blue;
  }
  .info {
    color: $gray-500;
  }
  .square {
    border-radius: $border-radius;
  }
  .occupancy-rate {
    flex: 1 0 calc(75% - 1rem);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: 1rem;
  }
  .column {
    flex: 1 0 calc(25% - 1rem);
    margin-left: 1rem;
    h2 {
      font-size: 1.675rem;
    }
    .visual {
      position: relative;
      margin-bottom: 0.75rem;
    }
    .label {
      font-weight: bold;
    }
    span {
      display: inline-block;
    }
    .square {
      position: absolute;
      top: 0.125rem;
      left: 0;
      width: 2.75rem;
      height: 2.75rem;
    }
    .description {
      margin-left: 3.5rem;
    }
    h2.about {
      margin-top: 2rem;
    }
    .about p {
      color: $gray-600
    }
  }
  .expectation-today, .expectation-tomorrow {
    @include card;
    flex: 1 0 calc(50% - 1rem);
    padding: 2rem;
    h3 {
      margin-bottom: 1.5rem;
    }
    span {
      vertical-align: middle;
      display: inline-block;
      margin-right: 1.5rem;
    }
    .square {
      width: 3rem;
      height: 3rem;
    }
    margin-bottom: 2rem;
  }
  .expectation-today {
    margin-right: 1rem;
  }
  .expectation-tomorrow {
    margin-left: 1rem;
  }
  .expectations {
    @include card;
    flex: 0 1 100%;
    .expectation {
      padding: 1rem 2rem;
      &:nth-child(even) {
        background-color: $gray-200;
      }
      span {
        vertical-align: middle;
        display: inline-block;
      }
      .day-of-the-week {
        width: 3rem;
        color: $gray-500;
        font-weight: bold;
      }
      .date {
        width: 5rem;
      }
      .square {
        height: 1.5rem;
        width: 1.5rem;
        margin-right: 0.75rem;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .expectation-today, .expectation-tomorrow {
      flex: 0 1 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }
  @include media-breakpoint-down(md) {
    .expectation-today, .expectation-tomorrow {
      margin-bottom: 1rem;
      margin-right: 0;
      padding: 1rem;
      h3 {
        margin-bottom: 1rem;
      }
    }
    .occupancy-rate {
      margin-right: 0;
      margin-bottom: 1rem;
    }
    .occupancy-rate, .column {
      flex: 0 1 100%;
    }
    .links a {
      font-size: 0.875rem;
    }
  }
}
