
#block-helper-search-holiday-homes, #block-helper-search-complexes, #block-helper-search-companies, #block-helper-search-events {
  .form-item {
    margin-bottom: 0;
  }
  .sort {
    display: flex;
    align-items: center;
    .result-count {
      font-weight: bold;
    }
    .view {
      position:relative;
      @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;
        .list {
          display: flex;
        }
        label {
          margin-right: 1rem;
          margin-bottom: 0;
        }
        a {
          display: flex;
          align-items: center;
        }
      }
      svg {
        fill: currentColor;
        height: 1rem;
        width: 1rem;
        margin-right: 0.5rem;
      }
    }
  }
  .form-item-sort {
    display:flex;
    align-items:center;
    flex-wrap: nowrap;
    > * {
      white-space:nowrap;
    }
  }
  label {
    font-size: 1rem;
    margin-right: 0.5rem;
  }
  .sod_select {
    max-width: 13rem;
    @include media-breakpoint-down(md){
      max-width:none;
    }
  }
}

#block-helper-search-holiday-homes {
  .sort {
    justify-content: space-between;
  }
}
#block-helper-search-complexes, #block-helper-search-companies,  #block-helper-search-events {
  .sort {
    @include media-breakpoint-up(md) {
      justify-content: space-between;
    }
  }
}
