form {
  label {
    margin-bottom: 0.125rem;
    font-weight: $font-weight-bold;
  }
  .form-item {
    margin-bottom: 1rem;
  }
  .description {
    font-size: 0.875rem;
    color: $gray-600;
    font-style: initial;
  }
  input[type="submit"]:not(.btn-primary,.btn-secondary){
    @include call_to_action;
  }
  .form-actions{
    > * {
      margin-right:1rem;
      &:last-child{
        margin-right:0;
      }
    }
  }

}

/* General Request */
#helper-general-request-general-request-form, #helper-prize-question-form {
  background: $step-background;
  padding: $booking-step-margin;
  > div {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
  }
  .form-item {
    margin-bottom: 1rem;
  }

  .form-type-textfield, .form-type-checkboxes, fieldset {
    width: 100%;
    @include media-breakpoint-up(lg) {
      width: calc(50% - 0.5rem);
    }
  }
  .form-type-textarea {
    width: 100%;
    border-bottom: $border-color;
  }
  hr {
    width: 100%;
  }
  .form-checkboxes, .fieldset-wrapper {
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;
  }
  .form-type-checkbox {
    width: 50%;
    @include media-breakpoint-up(lg) {
      width: 40%;
    }
    label {
      font-weight: normal;
    }
  }

  @include media-breakpoint-up(lg) {
    fieldset {
      border-right: 1px solid $hr-border-color;
      padding-right: 0.5rem;
    }
    .form-item-gr-date-from {
      margin-right: 1rem;
    }
  }
  .form-type-select {
    @include media-breakpoint-up(lg) {
      margin-top: 3rem;
      width: calc(16.66% - 0.5rem);
    }
  }
  .form-item-newsletter {
    width: 100%;
  }
  .submit {
    margin-top: 1rem;
  }
  input[type="submit"]{
    @include call_to_action;
  }
}
#helper-prize-question-form {
  .form-item-mail {
    width: 100%;
  }
}

#flean-manager-form{
  @include make-container();
  max-width: 1176px;
  .btn{
    margin-bottom:1rem;
  }
}

.form-control:disabled{
  background:white !important;
}
textarea,
input,select{
  &:disabled{
    opacity:.3 !important;
  }
}
