#image-gallery {
  min-height: 4rem;
  .spinner-border {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -2rem;
    margin-top: -2rem;
  }
  .image-gallery-left-nav, .image-gallery-right-nav, .image-gallery-fullscreen-button {
    svg {
      fill: $gray-300;
      height: 2.5rem;
      width: 2.5rem;
      filter: drop-shadow( 0 0 2px rgba(0, 0, 0, .75));
    }
    &:hover {
      svg {
        fill: $white;
        filter: drop-shadow( 0 0 3px rgba(0, 0, 0, .95));
      }

    }
  }
  .image-gallery-fullscreen-button {
    svg {
      height: 2rem;
      width: 2rem;
    }
  }
  @include media-breakpoint-down(md) {
    // margin-bottom: 2rem;
  }
}
#image-gallery .image-gallery:not(.fullscreen-modal) {
  /* YouTube video */
  .video-wrapper {
    position: relative;
    padding-bottom: 66.66%;
    height: 0;
    overflow: hidden;
    iframe, object, embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%
    }
  }
}

#image-gallery .image-gallery.fullscreen-modal {
  /* YouTube video */
  .video-wrapper {
    iframe, object, embed {
      width: 100%;
      height: calc(100vh - 64px);
    }
  }
}
