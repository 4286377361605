#faq {
  display: flex;
  margin-bottom: 3rem;

  #categories {
    min-width: 300px;
    margin-right: 2rem;

    h3 {
      margin-bottom: 1rem;
    }

    .category {
      a {
        margin-bottom: .25rem;
        font-weight: $font-weight-bold;
        padding: .75rem;
        display: block;
        border-radius: $border-radius;

        &:hover,
        &.is-active {
          text-decoration: none;
          background: white;
        }
      }
    }
  }

  #entries {
    width: 100%;

    fieldset {
      box-shadow: rgba(black, .2) .1rem .1rem .4rem;
      border-radius: $border-radius;
      background: $white;
      padding: 1rem;
      margin-bottom: 1rem;
      padding-bottom: 3rem;
      width: 100%;

      &.collapsed {
        opacity: .8;
      }

      &:hover {
        opacity: 1;
      }

      legend {
        position: relative;
        top: 2rem;
        font-size: 1.25rem;
        font-weight: $font-weight-bold;
        line-height: 1.2;
        z-index: 1
      }

      .fieldset-wrapper {
        padding-top: 3rem;

        p {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      &.collapsed {
        height: auto;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    display: block;
    #categories {
      min-width: 0;
      margin-right: 0;
      margin-bottom: 2rem;

      .category a {
        padding: .5rem;
      }
    }
  }
}
