
#reviews {
  .overall, .average-rating-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
  }

  .average-rating-desc-before, .rating-phrase {
    color: $primary;
    font-size: 1.1em;
    font-weight: bold;
  }
  .average-rating-desc-before{
    margin-top: 2rem;
  }
  .ratings{
    @include media-breakpoint-up(md){
      margin: 4rem 2rem !important;
    }
    @include media-breakpoint-down(md){
      display: flex;
      flex-direction: column;
      .overall {
        order: 2;
      }
    }
  }

  .average-rating {
    background: $primary;
    font-size: 1.1rem;
    color: $white;
    padding: .5rem .75rem;
    font-weight:bold;
    text-align: center;
    min-width: 2.5em;
    border-radius: $border-radius;
    display: inline-block;
    margin: 1rem 0;
    line-height: 1;
  }

  .overall .average-rating {
    font-size: 2.4rem;
    padding: 1.5rem 2rem;
  }

  .averages {
    > div {
      margin: 0.5rem 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .value {
        min-width: 1.75rem;
        font-weight: $font-weight-bold;
        color: $dark;
        margin-left: 1rem;
      }

      .outer {
        width: 100%;
        background: $gray-500;
        border-radius: $border-radius;
        .label {
          background: $primary;
          color: $white;
          line-height: 1;
          padding: 0.5rem 0;
          text-indent: 0.5rem;
          display: block;
          border-radius: $border-radius;
        }

      }

    }
  }

  h4 {
    font-family: $font-family-base;
    font-weight:bold;
    margin-bottom: 0.25rem;
  }

  .period-of-stay-and-name {
    color: $gray-700;
    margin-bottom: 1rem;
    font-size:.9em;
  }

  .review.row, .item-list {
    padding: 1rem 0;
    border-top: 1px solid $border-color;

    p {
      margin: 0;
    }
  }

  .general, .positive, .negative {
    position: relative;
    padding-left: 2rem;
    font-size: .9em;
    margin-bottom: .75rem;
    svg {
      position: absolute;
      left: 0;
      width: 1.3rem;
      height: 1.3rem;
      top: 0.1rem;;
      fill: white;
      border-radius: 1rem;
      padding: .3rem;
      background: green;
    }
  }

  .general svg {
    background: $primary;
  }

  .negative {
    svg {
      background: $gray-400;
      fill: white;
    }
  }

  .comment-owner {
    margin-top: 0.125rem;
    font-style: italic;
    color: $gray-700;
    padding-left: 1.5rem;
  }
}

#rent-reviews,
#block-helper-reviews-homepage {
  .reviews {
    @include reviews;
  }
}

