.side-block {
  h3 {
    margin-top: -0.25rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
}
.review-slider-wrapper {
  .slick-track {
    display:flex !important;
    align-items: center;
  }
  .slider-item {
    display: none;
    height:100%;
    padding: 0;
    .review {
      font-style: italic;
    }
    .name{
      font-style:italic;
      margin-top:.25rem;
    }
  }

  .slick-prev,
  .slick-next {
    width: 2.5rem;
    border:none;
    background:none;
    font-size:0;
    top:auto;
    padding:0;
    outline:none;
    cursor:pointer;
    color:$gray-600;
    &:before{
      font-size:14px;
    }
    &:hover{
    color: $primary;
    }
  }
  .slick-prev {
    @include icon(left-open);
    left: -2.5rem;
  }
  .slick-next {
    right: -2.5rem;
    @include icon(right-open);
  }
  @include media-breakpoint-down(md){
    .slick-prev{
      left:-1rem;
      width:1rem;
    }
    .slick-next{
      right:-1rem;
      width:1rem;
    }
  }

}
