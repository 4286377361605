//@charset "UTF-8";


// Default Variables
$slick-arrow-color: #efefef !default; // white !default;
$slick-dot-color: #0097d7 !default;  // black !default;
$slick-dot-color-active: #014587 !default; //$slick-dot-color !default;
$slick-prev-character: '\2190' !default;
$slick-next-character: '\2192' !default;
$slick-dot-character: '\2022' !default;
$slick-dot-size: 10px !default;
$slick-opacity-default: .75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: .75 !default;

/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  .slick-loading & {
    background: #fff url("#{$base_theme_path}/images/ajax-loader.gif") center center no-repeat;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}
.slick-slide {
  float: left;
  //height: 100%;
  //min-height: 1px;
  [dir="rtl"] & {
    float: right;
  }
  img {
    display: block;
  }
  &.slick-loading img {
    display: none;
  }

  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}

/* Dots */
.slick-dots {
  position: absolute;
  //bottom: -35px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  width: 100%;
  margin-top: 1rem;
  
  li {
    position: relative;
    display: inline-block;
    //height: 20px;
    //width: 20px;
    margin: 0 5px;
    padding: 0;
    button {
      border: none;
      height: 0.5rem;
      width: 0.5rem;
      margin: 0;
      padding: 0;
      border-radius: 50%;
      display: inline-block;
      text-indent: -9999px;
      background: $link-color;
      opacity: $slick-opacity-default;
      cursor: pointer;
      &:hover, &:focus {
        outline: none;
        opacity: $slick-opacity-on-hover;
      }
    }

    &.slick-active button {
      background: $primary;
    }
  }
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  z-index: 999;
  display: block;
  top: 50%;
  cursor: pointer;
  svg {
    position: absolute;
  }
}
.thumbs-prev, .thumbs-next {
  top: 0;
  height: 100%;
  &:hover {
    background: rgba(0,0,0,0.2);
  }
}