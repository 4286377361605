.page-node-ical{
  #block-system-main{
    table{
      @include card;
      background:white;
      thead{
        border-bottom: 2px solid $body-bg;
      }
      th h3{
        font-weight:bold;
      }
      tbody {

        td{
          vertical-align:top;
        }
      }
    }
  }
}
