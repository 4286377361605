/* Rental plans */
.node-type-rent-page {
  #main {
    //   max-width:900px;
    margin: 0 auto;
  }

  .field-advantages {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -1rem;
    padding: 0;

    li {
      display: block;
      box-sizing: border-box;
      list-style: none !important;
      position: relative;
      padding: 2rem 2rem 1rem 5rem !important;
      margin: 1rem;
      background: white;
      width: calc(100% / 3 - 2rem) !important;

      &:before {
        position: absolute;
        left: 2rem;
        font-size: 2em;
        color: green;
        font-family: 'icons';
        content: '\E826';
      }

      @include media-breakpoint-down(lg) {
        width: calc(100% / 2 - 2rem) !important;
      }
      @include media-breakpoint-down(md) {
        width: calc(100% - 2rem) !important;
      }
    }
  }


  h2 {
    margin-top: 4rem;
    margin-bottom:1rem;
  }

  .field-rent-review > p {
    font-weight: $font-weight-bold;
    font-size: 2rem;
    margin-top: 3rem;
  }

  #rent-reviews {
    @include reviews;
  }

  @include media-breakpoint-down(lg) {
    p {
      margin: 1rem 0 !important;
    }
  }
  .field-advantages + h2{
    margin-bottom:3rem;
    padding-bottom:1rem;
    border-bottom:1px solid $border-color;
  }

  .field-rental-plans {
    overflow: auto;
    max-width: 100%;
    margin: 2rem 0;


    table {
      th, td {
        vertical-align: top;
      }

      tr {
        border-bottom: 1px solid $border-color;

        &:last-child,
        &:first-child {
          border-bottom: none;
        }

        td {
          line-height: 1;
          padding: 15px 10px;
          width: 25%;
          text-align: center;

          &:first-child {
            width: 300px;
            text-align: left;
            border-left: none;

            font-weight: $font-weight-bold;
            padding-left: 0;
          }

          span.checked {
            background-color: green;
            //      background-image: url(#{$base_theme_path}/images/icons/general/check.svg);
            mask-image: url(#{$base_theme_path}/images/icons/general/check.svg);
            width: 1rem;
            height: 1rem;
            color: $primary;
            display: block;
            margin: 0 auto;
          }

          &:nth-child(2) {
            background: white;
            box-shadow: inset -4px 0 4px -4px rgba(black, .3);
          }

          &:nth-child(4) {
            background: white;
            box-shadow: inset 4px 0 4px -4px rgba(black, .3);

          }

          &:nth-child(3) {
            background: white;

            a {
           @include call_to_action;
            }
          }
        }

        a {
          background: $gray-600;
          color: $white;
          font-weight: $font-weight-bold;
          font-size: .875rem;
          border-radius: $border-radius;
          padding: 1rem;
          margin: .5rem;
          display: block;
          margin-left: .5rem;
          &:hover {
            background: darken($gray-600, 5);
            text-decoration: none;
          }
        }

        h3 {
          font-size: 1.375rem;
          font-weight: $font-weight-bold;

          + p {
            font-style: italic;
          }
        }

        &:first-child {
          td:nth-child(3) {
            &:before {
              content: " ";
              position: absolute;
              top: -57px;
              left: 550px;
              z-index: 99;
            }
          }
        }
      }
    }




  }
  h2.field-rent-faq-title{
    margin-bottom:1rem;
    padding-bottom:1rem;
    border-bottom:1px solid $border-color;
  }


  .field-rent-faq{
    padding:0;
    margin:0;
    list-style:none;
    li{
      padding:0;
      margin:0;
      list-style:none;
      border-radius:$border-radius;
      font-size:1.2em;
      margin-bottom:1rem;
      .field-question{
        padding:.5rem 0;
        border-bottom:1px solid $primary;
        color:$primary;
        margin-bottom:.5rem;
      }
      p{
        &:last-child{
          margin-bottom:0;
        }
      }
    }
  }
}


#helper-registration-form {
  width: 100%;
  float: left;

  .form-item {
    width: 100%;
    float: left;
    clear: left;

    label {
      width: 20%;
      padding-right: 10px;
      float: left;
    }

    input, textarea, select {
      width: 80%;
      float: left;
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 0;
      label {
        width: 100%;
        font-weight: $font-weight-bold;
      }
      input, textarea, select {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }

  #edit-salutation {
    float: left;

    .form-item-salutation {
      float: none;
      display: inline;

      label {
        width: inherit;
        float: none;
        font-weight: normal !important;
      }

      input {
        width: 14px;
        float: none;
      }
    }
  }

  #edit-submit {
    margin-bottom: 20px;
    margin-left: 20%;
    @include media-breakpoint-down(md) {
      margin-left: 0;
    }
  }

  textarea {
    height: 100px;
    line-height: 1.25;
  }

  @include media-breakpoint-down(md) {
    .form-item-street,
    .form-item-house-number,
    .form-item-zip-code,
    .form-item-city,
    .form-item-holiday-home-name,
    .form-item-holiday-home-city {
      display: none;
    }
  }
}
