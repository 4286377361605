#header > .header {
  background: $header-bg-color;
  z-index: 2;
  position: relative;

  &.header-top-strip-alt {
    .main {
      .main-menu {
        width: 100%;
        display: flex;

        ul.nav {
          margin-right: auto;

          + * {
            margin-left: auto;
          }
        }
        .search-toggle {
          @include media-breakpoint-up(xl) {
            margin-left: 1rem;
          }
        }
      }
    }
  }

  &.header-top-strip-alt,
  &.header-top-strip {
    @include media-breakpoint-down(md) {
      display: none;
    }

    .top {
      background: $top_strip_color;
      padding: .5rem 0;

      .container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        a {
          color: $top_strip_text_color !important;
        }
        .language-menu .language-link{
          color: $dark !important;
        }

        .questions .social-media-icons {
          display:flex;
          align-items: center;

          .title {
            display: none;
          }

          a{

            display:flex;
            align-items: center;
          }

          svg {
            height: 1rem;
            margin-left: 0.5rem;
            margin-right: 0;
          }
        }

        .questions, .questions .phone, .phone a {
          color: $top_strip_text_color !important;
        }

        .questions svg {
          fill: currentColor;
        }
      }
    }

    .main {
      padding: 1rem 0;

      .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1rem;
        font-weight: $font-weight-bold;

        .logo img {
          min-width: 100px;
          min-height: 0;
          max-width: 200px;
          max-height: 70px;
          width: 100%;
          height: auto;
        }

        .language-toggle {
          margin-left: 1rem;
          @include media-breakpoint-up(xl) {
            margin-left: 2rem;
          }
        }

        .language-menu {
          .dropdown-menu {
            margin-top: 0;
          }
        }
      }
    }
  }

  &.header-logo-left {
    @include media-breakpoint-down(md) {
      display: none;
    }

    .container {
      display: flex;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;


      .logo {
        margin-right: 2rem;

        img {
          max-height: 110px;
        }
      }

      .navigation {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 100%;

        .questions {
          font-size: 1rem;
        }

        .user-menu {
          display: flex;
          justify-content: space-between;
          @include media-breakpoint-between(md, lg) {
            font-size: 1rem;
          }
        }

        .main-menu {
          align-self: flex-end;
          display: flex;
          justify-content: flex-end;
          font-family: $navbar-font-family;
          font-size: 1.2rem;

          .language-menu {
            margin-left: 1rem;
          }

          .language-menu {
            .dropdown-menu {
              margin-top: 0;
            }
          }
        }
      }
    }
  }

  .header-bottom {
    z-index: 2;
    position: relative;
    background: $primary;
    display: flex;

    .col {
      justify-content: space-between;
      display: flex;
      font-size: 1.3em;
      @include media-breakpoint-between(md, lg) {
        font-size: 1.1em;
      }
      align-items: center;
      color: white;
    }
  }

  .main-menu {
    font-family: $navbar-font-family;
  }

  .user-menu {
    ul.nav {
      justify-content: flex-end;
      font-size: 1em;

      a {
        color: $user-menu-text-color;

        &:hover {
          color: $primary;
        }
      }
    }

    @include media-breakpoint-between(md, lg) {
      font-size: .9em;
    }
  }

  .questions {
    display: flex !important;
    align-items: center;
    color: $gray-600;
    font-size: 1.125rem;
    line-height: 1;

    .label {
      font-weight: $font-weight-bold;
      @include media-breakpoint-between(md, lg) {
        display: none;
      }
    }

    .phone, .phone a {
      color: $primary;
    }

    .icon {
      display: flex;
      align-items: center;
      margin: 0 .5rem;

      svg {
        fill: $tertiary;
        display: inline;
        width: 1em;
        height: 1em;
      }
    }
  }

  .logo img {
    max-height: 60px;
    max-width: 300px;
    min-width: 100px;
    min-height: 50px;
    @include media-breakpoint-between(md, lg) {
      //  max-height: 35px;
    }
  }

  a {
    color: $navbar-dark-color;
    font-weight: $navbar-font-weight;
    @include hover-focus {
      text-decoration: none;
      color: $navbar-dark-hover-color;
    }
  }

  nav {
    display: flex;

    li:first-child a {
      padding-left: 0;
    }

    .nav-link {
      @include media-breakpoint-up(xl) {
        margin-right: 1rem;
      }

      &.disabled {
        color: $navbar-dark-disabled-color;
      }

      .nav-item {
        font-weight: normal;
      }
    }

    .active > .nav-link,
    .active-trail > .nav-link {
      color: $navbar-dark-active-color;
    }
  }


  &.header-top-strip-alt,
  &.header-top-strip {
    a.logo {
      margin-right: 1rem;
    }

    nav {
      li:first-child a {
        padding-left: $nav-link-padding-x;
      }
    }
  }

  .language-menu {
    display: flex;
    position: relative;
    margin-left: auto;

    .language-toggle {
      display: flex;
      align-items: center;

      svg {

        height: 1em;

        &:first-child {
          margin-right: .5rem;
        }

        &:last-child {
          transition: transform .2s ease;
          fill: currentColor;

          .language-toggle-active & {
            transform: rotate(180deg);
          }
        }
      }
    }

    .dropdown-menu {
      margin-top: 1rem;

      .language-toggle-active & {
        display: block;
      }

      a {
        display: flex;
        align-items: center;
        color: $dark;
        &.active{
          background:none;
        }
        svg {
          margin-right: .5rem;
          height: 1em;
          display: inline;
        }
      }
    }
  }

  .search-toggle {
    display: flex;
    align-items: center;

    margin-left: 1rem;
    @include media-breakpoint-up(xl) {
      margin-left: 2rem;
    }

    cursor: pointer;
    margin-right: .5rem;
    width: 1em;

    .search-toggle-active & {
      transform: rotate(180deg);
      color: $navbar-dark-hover-color;
    }

    svg {

      transition: transform .2s ease;

      .search-toggle-active & {
        transform: rotate(-90deg);
      }

      fill: currentColor;
      height: 1em;
    }
  }
}


.search-wrapper {
  background: $secondary;

  .helper-search-autocomplete {
    margin: 1rem .5rem;
    display: none;

    input[type="text"] {
      border: none;
    }
  }

}

.helper-search-autocomplete {
  margin: 1rem 0 3rem 0;

  .search-toggle-active & {
    display: block;
  }

  .container-inline {
    display: flex;
    align-items: center;
    width: 100%;;

    .form-item, input {
      margin: 0;
    }

    input[type="text"] {
      background-position: calc(100% - .5rem) center;
    }

    input[type="submit"] {
      margin-left: 1rem;
    }

    .form-item {
      flex: 1;
    }
  }
}

#views-exposed-form-search-page {
  .container-inline {
    display: flex;
  }

  #edit-query-wrapper {
    //width: 100%;
    flex: auto;
  }

  .form-text {
    margin-top: 0;
  }

  input[type="submit"] {
    margin-left: 1rem;
  }
}

.view-search .view-filters {
  margin-bottom: 1rem;
}

.static-header {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 3rem 0;
  position: relative;
  z-index: 3;
  @include media-breakpoint-down(md) {
    .container {
      padding: 0;
      display: block !important;

      .static-area {
        display: flex;
        justify-content: center;
        margin-top: 2rem;
        margin-left: 0 !important;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    padding: 0;
    .container {
      padding: 0;
      display: block !important;

      .search-form,
      .static-area {
        border-radius: 0;
        padding: 1rem;
      }

      .static-area {
        margin-top: 0;
        margin-left: 0 !important;
        background: white;
      }
    }
  }

  .container {
    display: flex;
    justify-content: space-between;

    p {
      margin: 0;
    }

    > * {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 2rem;
      border-radius: $border-radius;
    }

    .search-form {
      flex: 2;
      background: $static_header_area_form_background_color;
      color: $static_header_area_form_text_color;

      form.is-column {
        .search-form-items {
          width: 100% !important;

          > div {
            width: 100% !important;
          }
        }
      }

      form.is-row.has-check-in-check-out {

        @include media-breakpoint-up(xl) {
          > div {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .form-item {
              width: calc(100% / 3 - 1rem);
            }
          }
        }
      }

      h1 {
        color: $static_header_area_form_text_color;
        font-family: $static-header-heading-font-family;
        font-weight: $static-header-heading-font-weight !important;
      }

      h1,
      .subtitle {
        margin-top: 0;
        margin-bottom: .5rem;
        line-height: 1;
      }

      .subtitle {
        font-size: 1.2em;
        margin-bottom: 1.5rem;
      }

      .search-form-items {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        > div {
          display: flex;
          width: calc(50% - 1rem);
          @include media-breakpoint-down(md) {
            width: 100%;
          }
        }

        .sod_select {
          &:before {
            color: black;
            font-size: 1.8em;
            font-family: 'icons';
            content: '\f107' !important;
            border: none !important;
            width: auto !important;
            height: auto !important;
            top: calc(50% - .4em);
            font-weight: $font-weight-bold;
            right: 1rem;
            margin-top: 0 !important;
          }
        }
      }

      input[type="submit"] {
        font-size: 1.4em;
        padding: .5rem;
        width: 100%;

        @include call_to_action;
      }
    }

    .static-area {
      margin-left: 2rem;
      background: $static_header_area_tips_background_color;
      width: $static_header_area_width;
      font-size: 1.125rem;
      @include media-breakpoint-down(md) {
        width: 100%;
      }

      h2, h3 {
        font-family: $static_header-heading-font-family;
        font-weight: $static_header_heading-font-weight;
        color: $static_header_area_tips_text_color !important;
        margin-bottom: .5rem;
        font-size: 1.375rem;
      }

      ul,
      li {
        list-style: none;
        padding: 0;
        margin: 0;
      }


      ul li {
        margin: 1.5rem 0;
        padding-left: 1.75rem;
        position: relative;
        line-height: 1.375;

        &:before {
          font-family: 'icons';
          content: '\e826';
          color: green;
          position: absolute;
          left: 0;
        }
      }
    }
  }
}

.front #helper-search-holiday-homes-form .sod_label {
  font-size: 1rem;
  line-height: 1;
  padding: .75rem;
  width: 100%;
  border: none;
}
