/*
    Mobile Menu Core Style
*/

.slicknav_btn {
  position: relative;
  display: block;
  vertical-align: middle;
  float: right;
  padding: 0.438em 0.625em 0.438em 0.625em;
  line-height: 1.125em;
  cursor: pointer;
}
.slicknav_menu .slicknav_menutxt {
  display: block;
  line-height: 1.188em;
  float: left;
}
.slicknav_menu .slicknav_icon {
  float: left;
  margin: 0.188em 0 0 0.438em;
}
.slicknav_menu .slicknav_no-text {
  margin: 0;
}
.slicknav_menu .slicknav_icon-bar {
  display: block;
  width: 1.125em;
  height: 0.125em;
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
}
.slicknav_btn .slicknav_icon-bar + .slicknav_icon-bar {
  margin-top: 0.188em;
}
.slicknav_nav {
  clear: both;
}
.slicknav_nav ul,
.slicknav_nav li {
  display: block;
}
.slicknav_nav .slicknav_arrow {
  font-size: 0.8em;
  margin: 0 0 0 0.4em;
}
.slicknav_nav .slicknav_item {
  display: block;
  cursor: pointer;
}
.slicknav_nav a {
  display: block;
}
.slicknav_nav .slicknav_item a {
  display: inline;
}
.slicknav_menu:before,
.slicknav_menu:after {
  content: " ";
  display: table;
}
.slicknav_menu:after {
  clear: both;
}
/* IE6/7 support */
.slicknav_menu {
  *zoom: 1;
}
/*
    User Default Style
    Change the following styles to modify the appearance of the menu.
*/

.slicknav_menu {
  font-size: 16px;
}
/* Button */
.slicknav_btn {
  margin: 5px 5px 6px;
  text-decoration: none;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background-color: #222;
}
/* Button Text */
.slicknav_menu .slicknav_menutxt {
  color: #fff;
  font-weight: $font-weight-bold;
  text-shadow: 0 1px 3px #000;
}
/* Button Lines */
.slicknav_menu .slicknav_icon-bar {
  background-color: #f5f5f5;
}
.slicknav_menu {
  background: #4c4c4c;
  padding: 5px;
}
.slicknav_nav {
  color: #fff;
  margin: 0;
  padding: 0;
  font-size: 0.875em;
}
.slicknav_nav,
.slicknav_nav ul {
  list-style: none;
  overflow: hidden;
}
.slicknav_nav ul {
  padding: 0;
  margin: 0 0 0 20px;
}
.slicknav_nav .slicknav_item {
  padding: 5px 10px;
  margin: 2px 5px;
}
.slicknav_nav a {
  padding: 5px 10px;
  margin: 2px 5px;
  text-decoration: none;
  color: #fff;
}
.slicknav_nav .slicknav_item a {
  padding: 0;
  margin: 0;
}
.slicknav_nav .slicknav_item:hover {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  background: #ccc;
  color: #fff;
}
.slicknav_nav a:hover {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  background: #ccc;
  color: #222;
}
.slicknav_nav .slicknav_txtnode {
  margin-left: 15px;
}
/* ADMiNiMAL MENU STYLING */
.slicknav_menu {
  display: none;
}
#admin-menu-menu-responsive {
  display: none;
}
@include media-breakpoint-down(md) {
  body.admin-menu.adminimal-menu:before {
    display: none;
  }
  #admin-menu-menu,
  a#edit-shortcuts,
  li.admin-menu-account,
  #admin-menu-search,
  #admin-menu-users {
    display: none;
  }
  #admin-menu {
    display: none !important;
  }
  body.admin-menu,
  body.admin-menu.adminimal-menu.adminimal-backend,
  body.admin-menu.adminimal-menu.adminimal-frontend {
    margin-top: 0 !important;
  }
  .slicknav_menu {
    display: block;
  }
  #admin-menu-shortcuts-responsive {
    background: url("#{$base_theme_path}/images/icons/general/circledown.svg") no-repeat scroll 16px 50% transparent;
    float: right;
  }
  #admin-menu-shortcuts-responsive select {
    -moz-appearance: none;
    -webkit-appearance: none;
    text-indent: 0.01em;
    text-overflow: '';
    border: none;
    font-size: 0;
    background: transparent;
    height: 46px;
    width: 46px;
    display: block;
  }
  #admin-menu-shortcuts-responsive select option {
    font-size: 14px;
    background: #fff;
    color: #333;
  }
}
.slicknav_btn {
  float: left;
}
.slicknav_menu {
  background: none repeat scroll 0 0 #222;
  padding: 0;
  font-family: Open Sans, "Segoe UI", "Helvetica", sans-serif;
  color: #fff;
}
.slicknav_menu a,
.slicknav_menu a:link,
.slicknav_menu a:active {
  color: #fff !important;
  text-decoration: none !important;
}
.slicknav_menu:after {
  clear: none;
}
.slicknav_btn {
  margin: 0;
  outline: medium none;
  padding: 5px 10px;
  text-shadow: none;
  border-radius: 0;
}
.slicknav_icon {
  display: none;
}
.slicknav_menu .slicknav_menutxt {
  text-shadow: none;
  font-weight: normal;
}
.slicknav_nav a:hover,
.slicknav_nav .slicknav_item:hover {
  border-radius: 0;
}
.slicknav_nav li.expandable,
#admin-menu .slicknav_nav .dropdown li {
  float: none !important;
}
.slicknav_btn {
  background: url("#{$base_theme_path}/images/icons/general/menu.svg") no-repeat scroll 8px 16px rgba(0, 0, 0, 0);
  font-size: 23px;
  padding: 10px 34px;
}
.slicknav_menu:after {
  clear: both;
}
.slicknav_nav .slicknav_item:hover,
.slicknav_nav a:hover {
  background: none repeat scroll 0 0 #0074bd;
  border-radius: 0;
  color: #fff;
  text-decoration: none !important;
}
.slicknav_nav .slicknav_arrow i {
  display: inline-block;
  background: url("#{$base_theme_path}/images/icons/general/menu-arrows.svg") no-repeat scroll 0 3px rgba(0, 0, 0, 0);
  font-size: 0.8em;
  height: 16px;
  margin: 0 0 0 0.4em;
  width: 16px;
}
.slicknav_open .slicknav_arrow i.open {
  background: url("#{$base_theme_path}/images/icons/general/menu-arrows.svg") no-repeat scroll -37px 0 rgba(0, 0, 0, 0);
}
.slicknav_nav li.admin-menu-toolbar-home-menu {
  background-color: #333;
}
li.admin-menu-icon.slicknav_parent > a {
  padding: 0;
  margin: 0;
}
li.admin-menu-icon.slicknav_parent > a > span.slicknav_arrow {
  position: absolute;
  margin-top: 10px;
  margin-left: -5px;
}
.slicknav_nav li.admin-menu-toolbar-home-menu span.admin-menu-home-icon {
  background: url("#{$base_theme_path}/images/icons/general/house.svg") no-repeat scroll 17px 10px rgba(0, 0, 0, 0);
  display: inline-block;
  height: 40px;
  width: 50px;
}
