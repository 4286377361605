body {
  font-weight: $font-weight-normal;
}

strong {
  font-weight: 700;
}

figure {
  margin-bottom: .5rem;
}

.caption {
  font-size: .9em;
  text-align: center;
  color: $gray-600;
  font-style: italic;
}
.content > *{
  hyphens: auto
}
blockquote {
  margin: 1rem 0;
  padding: 1rem;
  border-left: 3px solid $primary;
  font-style: italic;
}

.embedded-image,
.oembed-wrapper {
  margin: 1rem 0;

  img {
    width: 100%;
    height: auto;
  }
}

.oembed-wrapper {
  margin: 1rem auto;

  &.youtube {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  iframe {
    width: 100% !important;
  }

  twitter-widget {
    margin: 0 auto;
  }

  iframe.instagram-media {
    margin: 0 auto !important;
  }

}

.embedded-highlight {
  background: rgba($primary, .1);
  margin: 1rem 0;
  border-radius: $border-radius;
  border: 2px solid $primary;

  header {
    background: $primary;

    h5 {
      font-size: 1.25rem;
      color: $white;
      font-weight: $font-weight-bold;
    }

    padding: .5rem 1rem;
  }

  main {
    @include clearfix;
    padding: 1rem;

    figure {
      float: right;

      img {
        border-radius: $border-radius;
      }

      @include media-breakpoint-down(sm) {
        width: 50%;
        margin-bottom: 1rem;
        img {
          width: 100%;
        }
      }
    }

    .button-wrapper a {
      @include call_to_action;
    }
  }
}

.embedded-highlight-simple {
  margin: 1rem 0;
  display: flex;

  @include media-breakpoint-down(xs){
  display:block;
  }
  .left {
    margin-right: 1rem;
    flex: none;
    width: calc(33.3333% - 1rem);
    max-width: 150px;
    @include media-breakpoint-down(xs){
      width:100%;
      max-width: none;
    }
  }

  font-family: $font-family-base;
  font-size: .9rem;

  h5 {
    font-size: 1.4rem;
  }

  figure {
    img {
      width: 100%;
      border-radius: $border-radius;
    }
  }

  .button-wrapper a {
    @include call_to_action(white);
    border: 1px solid $border-color;
    color: $body-color;
    @include icon('angle-right', 'after');

    &:hover {
      background: $body-color;
    }

    &:after {
      margin-left: .5rem;
    }
  }
}
