

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@mixin spinner{
  font-size:1.6em;
  .throbber {
    position: absolute;
    left: calc(50% - (1em / 2));
    top: calc(50% - (1em / 2));
    background: none;
    line-height: 0;
    &:after {
      animation: spin .5s infinite linear;
      border: 2px solid $spinner-color;
      border-radius: 290486px;
      border-right-color: transparent;
      border-top-color: transparent;
      content: "";
      display: block;
      height: 1em;
      position: relative;
      width: 1em;
    }
  }
}