/* Pager */
div.item-list {
  ul.pager {
    display:flex;
    margin: 0 0 $half-gutter-width 0;
    padding: 0;
    li {
      margin: 0;
      padding: 0;
      border: 1px solid $border-color;
      border-radius: $border-radius;
      list-style: none;
      background: $white;
      margin-right: 0.375rem;
      display: block;
      margin-bottom:.375rem;
    }
    a, li.pager-current {
      line-height: 1;
      font-size: 1rem;
      padding: 0.75rem 1rem;
      color: $body-color;
    }
    li.pager-current, a:hover {
      color: $white;
      background: $link-color;
    }
    a {
      display: block;
      &:hover {
        text-decoration: none;
      }
    }
    .pager-ellipsis{
      background:none;
      border:none;
      line-height: 1;
      padding: 0.75rem .25rem;
      vertical-align: center;
    }
  }
}