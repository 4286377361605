#booking-widget {
  margin-bottom: 2rem;
  .filter-buttons {
    button {
      margin-right: 0.75rem;
      @include media-breakpoint-down(xs) {
        display: inline-block;
      }
    }
  }
  .filter-buttons, .dropdown {
    svg {
      fill: $white;
      height: 1rem;
      width: 1rem;
      margin-right: 0.375rem;
      margin-bottom: 0.25rem;
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
    button {
      margin-bottom: 0.75rem;
    }
  }
  .offers {
    margin-top: 1rem;
    @include media-breakpoint-up(lg) {
      margin-top: 1.5rem;
    }
  }
  .offer, .search {
    @include media-breakpoint-up(md) {
      .btn {
        min-width: 6.5rem;
      }
    }
  }
  #prices {
    button {
      &.btn-outline-primary {
        background: $white;
        &:hover, &:focus, &.focus {
          background: $primary;
          color: $white;
        }
      }
    }
    @include media-breakpoint-down(xs) {
      h2 {
        margin:0
      }
    }
  }
  .col-12 {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    @include media-breakpoint-down(xs) {
      text-align: center;
      justify-content: center;
    }
    align-items: center;
    margin-bottom: 1rem;
    label {
      margin-bottom: 0;
      width: 8.25rem;
      margin-right: 0.25rem;
      font-weight: $font-weight-bold;
      @include media-breakpoint-down(xs) {
        flex: 1 1 100%;
      }
    }
  }
  .filter-buttons .dropdown-toggle, .spinner-wrapper {
    text-align: left;
    @include media-breakpoint-down(md) {
      width: 10rem;
    }
    @include media-breakpoint-up(lg) {
      padding-right: 1.5rem;
    }
  }
  .spinner-wrapper.btn {
    position: relative;
    .spinner-border {
      position: absolute;
      right: 0.5rem;
      top: 0.675rem;
      height: 1rem;
      width: 1rem;
    }
  }
  .filter-buttons .dropdown-toggle {
    &::after {
      position: absolute;
      right: 0.5rem;
      top: 1rem;
    }
  }
  @include media-breakpoint-up(xl) {
    .row.offer, .row.search, .row.info, .row.show-all, .supplements, #calendar .calendar {
      padding-left: 4rem;
      padding-right: 4rem;
    }
    .row.filter {
      padding-left: 4rem;
    }
  }
  @include media-breakpoint-only(lg) {
    .row.filter {
      padding-right: 4rem;
    }
  }
  .row.show-all {
    margin-top: 1rem;
  }
  .offers, .supplements {
    .spinner-wrapper {
      margin: 1rem 0;
      .spinner-border {
        height: 3rem;
        width: 3rem;
        margin: 0 auto;
      }
    }
  }
  .info .col {
    font-size: 0.875rem;
    color: $gray-600;
    font-style: italic;
    margin-top: .25rem;
  }
  #calendar {
    margin-top: 2rem;
    .calendar.below {
      margin-top: 1rem;
    }
    .col-md-6.col-lg-4 {
      min-height: 18rem;
    }
  }
  .search {
    .arrival, .departure {
      .calendar {
        position: absolute;
        width: 20rem;
        @include media-breakpoint-down(sm) {
          width: 16rem;
        }
        height: 15rem;
        z-index: 99;
        left: 0.5rem;
        &.below {
          top: 2rem;
        }
        &.above {
          bottom: 2.5rem;
        }
        div {
          float: none;
          margin-right: 0;
        }
      }
    }
  }
  .offers {
    .offer.row {
      &:first-child {
        border-top: 1px solid $border-color;
      }
      padding-top: 1rem;
      padding-bottom: 1rem;
      align-items: center;
    }
  }
  .offers.checkincheckout {
    .offer.row {
      &:first-child {
        border-color: $secondary;
      }
      &:nth-child(2) {
        border-top: 1px solid $border-color;
        margin-top: 3rem;
      }
    }
  }
  .offer {
    border-bottom: 1px solid $border-color;
    @include media-breakpoint-down(md) {
      &.row {
        .col-6.period {
          padding-right: 0.25rem;
        }
        .col-3.price {
          padding-left: 0.25rem;
          padding-right: 0;
        }
      }
      @include media-breakpoint-down(sm) {
        margin-left: 0;
        margin-right: 0;
        .col-6.period {
          padding-left: 0;
        }
        .col-3.book {
          padding-right: 0;
        }
      }
    }
    .period {
      .divider {
        margin-left: 0.5rem;
        svg {
          height: 1rem;
          width: 1rem;
          margin-bottom: 0.125rem;
          fill: $body-color;
        }
      }
      div {
        @include media-breakpoint-up(md) {
          float: left;
        }
        margin-right: 0.5rem;
        &.arrival, &.departure {
          margin-right: 0;
          > div {
            margin-right: 0;
            @include media-breakpoint-down(sm) {
              font-size: 0.8125rem;
            }
          }
        }
        &.departure em {
          margin-left: 0.5rem;
          text-transform: lowercase;
          font-variant: small-caps;
          color: $gray-600;
          font-style: normal;

        }
      }
      span {
        position: relative;
      }
    }
    .price-original {
      @include media-breakpoint-up(sm) {
        display: inline-block;
      }
      text-decoration: line-through;
      font-size: 0.875rem;
      @include media-breakpoint-up(sm) {
        margin-right: 0.75rem;
      }
      color: $gray-700;
    }
    .price {
      text-align: right;
      @include media-breakpoint-down(sm) {
        font-size: 0.875rem;
      }
    }
    .book {
      text-align: right;
      @include media-breakpoint-only(lg) {
        padding-right: 4rem;
        //text-align: center;
      }
      @include media-breakpoint-down(sm) {
        button {
          //font-size: 0.875rem;
        }
      }
    }
    .form-text {
      display: inline-block;
      width: 10rem;
      margin: 0 0.5rem;
    }
    .arrival .form-text {
      margin-right: 1rem;
    }
    &.selected {
      font-weight: $font-weight-bold;
    }
  }
  .supplements {
    @include make-row();
    border-bottom: 1px solid $border-color;
    margin-top: 1rem;
    padding-bottom: 2rem;
    > div {
      width: 100%;
    }
    @include media-breakpoint-down(sm) {
      margin-left: 0;
      margin-right: 0;
    }
  }
  .supplements-wrapper {
    h3 {
      margin-top: 1rem;
      text-align: left;
    }
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(lg) {
      flex-direction: row;
      div {
        width: 33.3333333333%;
        padding: 0 $half-gutter-width;
        &:last-child {
          padding-right: 0;
        }
      }
      h3 {
        margin-bottom: 0;
      }
    }
    ul {
      margin: 0.5rem 0 0 0;
      padding: 0;
    }
    li {
      list-style: none;
      padding-left: 1rem;
      position: relative;
      svg {
        height: 0.75rem;
        position: absolute;
        left: 0;
        top: 0.375rem;
        fill: $secondary;
      }
      label {
        margin: 0;
        padding: 0;
      }
    }
    h4 {
      font-weight: $font-weight-bold;
      margin: 0.5rem 0 -0.25rem 0;
    }
  }
  .search {
    padding-bottom: 1.75rem;
    @include media-breakpoint-down(md) {
      .arrival {
        padding-bottom: 0.5rem;
      }
      label {
        width: 2.5rem;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .search.row {
      > div {
        flex: 0 0 100%;
        max-width: 100%;
        > div, span, input, input.form-text {
          width: 100%;
          margin-right: 0;
          display: flex;
        }
      }
      .col-6.period {
        padding-right: 0;
      }
      .col-3.price, .col-3.book, .book button {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
      }
      .col-3.price {
        text-align: center;
        margin: 1rem 0;
        font-size: 1rem;
      }
    }
  }
  #prices {
    .col-12.checkincheckout .search-form {
      display: flex;
      flex: 100%;
      margin-left: -$half-gutter-width;
      margin-right: -$half-gutter-width;
      margin-bottom: 0;
      padding: 1rem;
      background: $search-overview-bg-color;
      @include media-breakpoint-down(sm) {
        margin-left: 0;
        margin-right: 0;
      }

      border-radius: $border-radius;
      @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
      }
      .arrival, .departure {
        padding-bottom: 0;
        flex-direction: column;
        @include media-breakpoint-down(sm) {
          width: 100%;
        }
        span {
          position: relative;
          .calendar {
            left: 0.125rem;
            &.below {
              top: 2.6125rem;
            }
          }
        }
      }
      .departure {
        @include media-breakpoint-down(sm) {
          margin-top: 0.5rem;
        }
      }
      .persons {
        flex-direction: column;
        @include media-breakpoint-up(sm) {
          margin: 0 auto;
        }
        @include media-breakpoint-down(sm) {
          width: 100%;
          margin-top: 0.5rem;
        }
        .dropdown {
          margin-top: 0.25rem;
        }
        .btn-outline-primary {
          border-color: $input-border-color;
          background: $white;
          color: $input-color;
          &::after {
            right: 2.25rem;
          }
          &:focus, &.focus {
            background: $white;
            color: $input-color;
          }
        }
      }
      label.col-form-label {
        padding-top: 0;
        padding-bottom: 0;
        width: 100%;
      }
      .change {
        display: block;
        margin-top: 1.75rem;
        @include media-breakpoint-only(lg) {
          padding-right: 3rem;
        }
        @include media-breakpoint-down(sm) {
          margin-top: 1rem;
          width: 100%;
          button {
            width: 100%;
          }
        }
      }
    }
  }
}

#price-info {
  .row {
    align-items: center;
  }
  .period {
    font-size: 0.875rem;
    color: $gray-600;

  }
  .price {
    font-weight: $font-weight-bold;
    font-size: 1.25rem;
    padding-right: 0;
    .price-original {
      font-size: 0.875rem;
      text-decoration: line-through;
      color: $gray-600;
      font-weight: normal;
    }
  }
}
