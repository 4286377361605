#search-form-wrapper .search {
  min-height: 50px;
  position: relative;

  .loading {
    background: rgba($search-home-bg-color, .6);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include spinner;
    min-height: 50px;
  }
}

#block-helper-search-search-holiday-homes {
  input[type="submit"] {
    @include call_to_action;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  h2 {
    margin-bottom: .5rem;
    color: $white;

    .not-front & {
      font-size: 1.25rem;
    }
  }

  #sidebar-first & {
    background: $search-overview-bg-color;
    padding: $half-gutter-width;
    width: 100%;
    position: relative;
    z-index: 3;
    @include media-breakpoint-up(lg) {
      border-radius: $border-radius;
    }

    .search-form-items {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;

      .front & {
        @include media-breakpoint-up(lg) {
          flex-direction: row;
          margin-left: -15px;
        }
      }
    }

    .form-item {
      margin-bottom: 0.5rem;
      width: 100%;

      .front & {
        margin-bottom: $half-gutter-width;
        @include media-breakpoint-up(lg) {
          margin-left: $half-gutter-width;
          width: 240px;
        }
      }
    }

    .form-submit {
      width: 100%;
      margin: .5rem 0;
    }
  }
}

.front .display-column {
  #block-helper-search-search-holiday-homes {
    .form-item, .form-submit {
      width: 100%;
    }

    @include media-breakpoint-up(lg) {
      border-radius: $border-radius;
      background: $search-home-bg-color;
      padding: ($grid-gutter-width);
      z-index: 3;
      position: relative;
      width: 60%;
      @include media-breakpoint-up(xl) {

        width: 50%;
      }
      h2 {
        font-size: 1.4em;
      }
      .search-form-items {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
      }
      .form-item {
        width: calc(50% - 1rem);
      }
    }
  }
}

.front .header-image-wrapper.display-row {
  #block-helper-search-search-holiday-homes {
    @include media-breakpoint-up(lg) {
      top: 282px;
    }
  }
}

.front .slider-wrapper.display-row {
  #block-helper-search-search-holiday-homes {
    @include media-breakpoint-up(lg) {
      top: 66px;
    }
  }
}

.display-row {
  .container {
    position: relative;
  }

  &.single-image {
    margin-bottom: 2rem;

    @include media-breakpoint-up(lg) {
      min-height: 375px;
    }

    #search-form-wrapper > h2 {
      float: none !important;
      padding-bottom: 0 !important;
      padding-left: 1rem !important;
      @include media-breakpoint-up(lg) {
        float: left !important;
        padding: 0.5rem 0.5rem !important;
      }
    }

    #search-form-wrapper {
      .search {
        padding-bottom: 1rem;
        @include media-breakpoint-up(md) {
          padding-bottom: 0;
        }
      }
      form {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
        background: $search-home-bg-color;
        padding: 1rem;
        @include media-breakpoint-up(lg) {
          background: unset;
        }
      }
    }

    #block-helper-search-search-holiday-homes {
      padding-top: 20px;
      @include media-breakpoint-up(md) {
        padding-bottom: 20px;
      }
      .form-item {
        margin-bottom: 1rem;
      }
      .sod_select {
        background: $search-home-bg-color;
      }
      h2 {
        color: $white;
        line-height: 1;
        float: left;
        background: $search-home-bg-color;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
        font-size: 1.25rem;
        font-weight: $font-weight-bold;
        padding: 0.5rem 0.5rem;
        margin: 0;
      }
      @include media-breakpoint-up(lg) {
        padding-top: 0;
        top: 250px;
        .form-item {
          margin-bottom: unset;
        }
        .sod_select {
          padding: unset;
          background: unset;
        }
      }
    }
    .usp-wrapper {
      display: block !important;
      text-align: center;
      padding-top: 1rem;
      color: #fff;
      font-size: 1.25rem;
      text-shadow: rgba(black, .7) 1px 1px 4px;
      h3,h4,h5 {
        color: #fff;
        font-size: 2rem;
      }
      @include media-breakpoint-up(lg) {
        top: 165px;
        display: flex !important;
        padding-top: unset;
        .usp {
          font-size: 1.5rem;
        }
      }
    }
  }

  #block-helper-search-search-holiday-homes {
    width: 100%;
    @include media-breakpoint-up(lg) {
      position: absolute;
      z-index: 3;
      padding-right: $grid-gutter-width;
      #helper-search-holiday-homes-form {
        clear: left;
        background: $search-home-bg-color;
        padding: 0.5rem;
        border-top-right-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;

        > div {
          display: flex;
          flex-direction: row;
        }

        @include media-breakpoint-only(lg) {
          .sod_label,
          input[type="submit"] {
            font-size: .9em;
          }
        }
      }
      #search-form-wrapper {
        float: left;
        clear: both;
        width: 100%;

        &.no-title {
          margin-top: 2.25rem;
        }

        .loading {
          background: rgba($search-home-bg-color, .6);
          min-height: 56px;
          border-radius: $border-radius;

          &:after {
            font-size: 1.4em !important;
            top: calc(50% - .7em);
            left: calc(50% - .7em);
          }
        }
      }
      h2 {
        color: $white;
        line-height: 1;
        float: left;
        background: $search-home-bg-color;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
        font-size: 1.25rem;
        font-weight: $font-weight-bold;
        padding: 0.5rem 0.5rem;
        margin: 0;
      }
      .form-item, .form-submit {
        @include media-breakpoint-up(lg) {
          width: 20%;
          padding-right: 0.5rem;
          margin: 0;
        }
      }
      .form-submit {
        padding-left: 0;
        padding-right: 0;
      }
      #search-form-wrapper.no-destinations {
        .form-item, .form-submit {
          @include media-breakpoint-up(lg) {
            width: 25%;
          }
        }
      }
    }
  }
}

#helper-event-search-block-form {
  background: $search-overview-bg-color;

  @include media-breakpoint-up(lg) {
    border-radius: $border-radius;
  }
  padding: 1rem;

  h2 {
    color: white !important;
    font-size: 1.25em;
    margin-bottom: 15px;
  }

  .search-form-items {
    margin: .25rem 0;
  }

  input[type="submit"] {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: .5rem;
    font-size: .9em;
    background: $primary;
    border: none;
    color: white;

    &:hover {
      background: darken($primary, 4);
    }
  }

  .custom {
    .form-item-from, .form-item-from-date {
      margin-bottom: 0;
    }

    .description {
      display: none;
    }

    label {
      font-size: .9em;
      color: white;
    }

    .btn {
      font-size: 1em;
      background: $secondary;

      &:hover {
        background: darken($secondary, 5);
      }
    }
  }

  .front & {
    background: none;
    padding: 0;

    h2 {
      color: inherit !important;
      font-family: $font-family-base;
      font-size: .9rem;
      font-weight: bold !important;
      margin-bottom: .25rem;
    }

    .buttons {
      display: flex;
      justify-content: flex-start;

      > * {
        margin-right: .5rem;
        width: auto;

      }
    }

    .custom {
      margin-top: 1rem;

      .form-type-date-popup {
        display: none;
      }

      #edit-filters {
        display: flex;
        justify-content: space-between;

        > * {
          width: calc(100% / 3 - .5rem);
        }
      }

      .btn {
        padding: .6rem;
        background: $call_to_action_bg;
      }
    }
  }
}
