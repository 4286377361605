#filters-mobile,
#sidebar-first {
  .block-helper-search {
    &.facet {
      h3 {
        @include icon('down-open', 'after');
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:after {
          font-size: .75rem;
          transition: all .2s ease-in-out;
          transform: rotate(180deg);
        }
      }

      &.collapsed {
        h3:after {
          transform: rotate(0deg);
        }

        > ul {
          display: none;
        }
      }
    }

    h3 {
      font-size: 1.25rem;
      margin-top: 1.5rem;
      margin-bottom: 0;
    }

    &.facet:first-child h3 {
      margin-top: 0;
    }

    ul {
      margin: 0;
      padding: 0;
    }

    li {
      list-style: none;
      padding: 0;
      margin: 0.25rem 0 0 0;
      display: inherit;
      align-items: center;
      padding-left: 1.75rem;
      position: relative;

      a {
        &:before,
        &:after {
          left: 0;
          position: absolute;
          top: .25rem;
        }

        &:before {
          background: white;
          display: block;
          content: ' ';
          width: 1.125rem;
          height: 1.125rem;
          border-radius: 2px;
          border: 1px solid #ddd;
          box-shadow: rgba(black, .1) 0px .01rem 2px;
        }

        &:after {
          color: black;
          left: 3px;
          font-size: 0.8125rem;
          top: 3px;
          opacity: 0;
        }

        @include icon('ok', 'after');

        &:hover {
          &:after {
            opacity: .2;
          }
        }

      }

      &.selected a:after {
        opacity: 1;
      }
    }

    li, a {
      color: $gray-700;
    }

    li a {
      margin-right: .25rem;
    }

    a.erase {
      margin-left: .5rem;
      background: $secondary;
      color: white;
      font-size: .625rem;
      padding: .1rem .25rem;
      border-radius: $border-radius;

      &:hover {
        background: darken($secondary, 10);
      }
    }
  }
}

.block-helper-search .filters {
  display: flex;
  flex-wrap: wrap;

  a.button {
    background: $search-filter-tag-color;
    padding: .5rem .75rem;
    display: flex;
    white-space: nowrap;
    align-items: center;
    border-radius: 3px;
    margin-right: 1rem;
    margin-bottom: .5rem;
    color: white;
    line-height: 1;
    font-size: .9375rem;

    &:hover {
      text-decoration: none;
      background: darken($search-filter-tag-color, 10);
    }

    svg {
      margin-left: .5rem;
      color: white;
      fill: white;
      width: 1rem;
      height: 1rem;
    }
  }
}

#search-filter-toggle {
  display: none;
}

#helper-search-holiday-homes-sort-form .form-item .sod_select {
  min-width: 200px;
  @include media-breakpoint-down(md){
    min-width:0;
  }
}

@include media-breakpoint-down(md) {
  .filters {
    margin-bottom: .5rem;
    margin-top: .5rem;
    border-bottom: none !important;
  }
  .search-view-toggle-active {
    .sort .view {
      label {
        margin-bottom: 0 !important;

        &:after {
          transform: rotate(180deg);
        }
      }

      > .list {
        display: flex !important;
      }
    }
  }
  .search-toggle-enabled.search-filter-toggle-active {
    #search-filter-toggle {
      &:after {
        transform: rotate(180deg);
      }
    }

    #filters-mobile {
      > * {
        display: block !important;
      }
    }
  }

  .search-toggle-enabled {


    .sort {
      position: relative;
      margin-bottom: 0 !important;
      border-bottom: none !important;
      padding-bottom: 0 !important;
      flex-wrap: nowrap;
      @include media-breakpoint-down(xs) {
        flex-wrap: no-wrap;
      }

      > * {
        margin-bottom: 1rem !important;
        width: calc(50% - .5rem);
        @include media-breakpoint-up(sm) {
          margin-right: 1rem;
        }

        .sod_select {
          width: 100% !important;
        }
      }

      .view {
        display: none;

        > .list {
          display: none;
          position: absolute;
          flex-direction: column;
          z-index: 100;
          font-size: .8em;
          width: calc(100%);

          > a {
            @include media-breakpoint-down(lg) {
              &.photos {
                display: none;
              }
            }
            margin-left: 0 !important;
            padding: .5rem 1rem !important;
            background: white !important;
            border: 1px solid $border-color;
            border-top: none;

            &.active,
            &:hover {
              background: $secondary !important;
              color: white;
              text-decoration: none;

              svg {
                fill: currentColor !important;
              }
            }
          }
        }
      }
    }

    // Theme the toggls like dropdown buttons.
    #helper-search-holiday-homes-sort-form .form-item .sod_select {

      max-width: none;
      font-size: .8125rem !important;

      &.focus {
        box-shadow: none !important;
      }

      width: auto;

      &:before {
        display: none;
      }
    }

    #helper-search-holiday-homes-sort-form .form-item .sod_select select,
    #helper-search-holiday-homes-sort-form .form-item .sod_label,
    #search-filter-toggle,
    .sort .view label {
      background: white;
      margin-right: 0 !important;
      font-size: .8125rem !important;
      @include icon('down-open', 'after');
      @include icon('eye', 'before');
      margin-bottom: 0;

      &:after {
        margin-left: auto;
        padding-left: .5rem;
      }

      &:before {
        margin-right: .5rem;
      }

      display: flex;
      justify-content: space-between;
      align-items: center;
      border: $border-color 1px solid;
      padding: .25rem .5rem;
      height: 3rem;
      font-family: $font-family-base;
      cursor: pointer;
      box-shadow: none;
      border-radius: $border-radius;

      .sod_label {
        border: none;
        padding: 0;
      }
    }

    #search-filter-toggle {
      @include icon('filter', 'before');
    }

    #helper-search-holiday-homes-sort-form .form-item .sod_label {
      @include icon('sort', 'before');
    }

    #helper-search-holiday-homes-sort-form .form-item label {
      display: none;
    }

    @include media-breakpoint-down(md) {
      #sidebar-first{
        display:none;
      }
      #filters-mobile{
        > * {
          display: none;
          &:last-child{
            margin-bottom:3rem;
          }
        }
      }
      #block-helper-search-holiday-homes .sort{
        display:flex;
        > *{
          margin:0;
        }
      }
    }
  }
}
// A copy of the filters is rendered after the sort.
@include media-breakpoint-up(lg){
  #filters-mobile{
    display:none;
  }
}

