body.page-user:not(.membership) { // Login page without membership module enabled.
  #main{
    max-width: 600px;
    margin: 3rem auto;
  }
}
.page-user {
  #content .not-node {
    display: flex;
    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
    #user-login {
      padding: $grid-gutter-width;
      background: $white;
      border: 1px solid $border-color;
      border-radius: $border-radius;
      margin: 0 auto;
    }
    .block.register {
      margin-left: $grid-gutter-width;
      @include media-breakpoint-down(md) {
        margin-left: 0;
        margin-top: 2rem;
      }
      ul {
        margin: 1rem 0;
        padding: 0;
      }
      li {
        list-style: none;
        margin: 0 0 0.5rem 0;
        padding: 0;
        svg {
          width: 1.5rem;
          height: 1.5rem;
          fill: $secondary;
          margin-right: 0.375rem;
        }
      }
    }
  }
}
