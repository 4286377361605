

.features {
  width:100%;
  ul {
    width:100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      font-size: 0.875rem;
      color: $gray-600;
      list-style: none;
      margin-bottom:.25rem;
      min-height:2rem;
      display: flex;
      align-items: center;
      width:50%;
      @include media-breakpoint-between(md, lg){
        width:100%;
      }
      .full & {
        max-width: 50%;
        min-width: 40%;
        &:nth-child(n+7){
          display:none;
        }
      }
      .node-teaser &{
        &:nth-child(n+5){
          display:none;
        }
        @include media-breakpoint-down(lg){
          &:nth-child(n+3){
            display:none;
          }
        }
      }
      @include media-breakpoint-down(lg) {
        min-width: 50%;
      }
      svg {
        fill: $tertiary;
        width: 1rem;
        margin-right: 0.5rem;
      }
    }
  }

  .show-all {
    clear: left;
  }
}