.paragraphs-items {
  h2{
    font-size:1.5rem;
  }
  .field-paragraphs {
    padding:3rem 0;
  }
  .front & .field-paragraphs:nth-child(even) {
    @include alternate;
  }
  .not-front & .field-paragraphs:nth-child(odd) {
    @include alternate;

  }
}
.paragraphs-header {
  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: space-between;
    margin-bottom: $grid-gutter-width/2;
    align-items: center;

    h2{
      margin-right:.5rem;
    }
    a {
      margin-left:0;
      white-space: nowrap;
    }

  }
  @include media-breakpoint-down(sm){
    h2,h1{
      font-size:1.2rem;
    }
    a{
      margin-bottom:1rem;
    }
  }
}

.paragraphs-content > .page-blocks {
  margin-bottom: -3rem;
}
.paragraphs-content .row.columns-4{
  > * {
    max-width: 25%;
    flex: 0 0 25%;
    @include media-breakpoint-down(lg){
      max-width:50%;
      flex: 0 0 50%;
    }
    @include media-breakpoint-down(sm){
      max-width:100%;
      flex: 0 0 100%;
    }
  }
}

.paragraphs-content .row.columns-3{
  > * {
    max-width: calc(100%/3);
    flex: 0 0 calc(100%/3);
    @include media-breakpoint-down(md){
      max-width:100%;
      flex: 0 0 100%;
    }
  }
}

.paragraphs-content .row .holiday-home-teaser{
  @include media-breakpoint-up(lg) {
    margin-bottom: 2rem;
  }
}

.paragraph-map{
  margin-top:-3rem;
  margin-bottom:-3rem;
  width:100%;
  padding-bottom:30%;
  height:0;
  position:relative;
  #ol_map{
    position:absolute;
    height:100%;
    width:100%;
  }
  #ol-hover > div{
    font-weight: bold;
    z-index:999;
    background:white;
    padding:.5rem;
    border-radius:4px;
    box-shadow:rgba(black,.3) 0 0 4px;
    .description{
      max-width:300px;
      font-size:.7rem;
    }
  }
}
