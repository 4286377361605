.view-taxonomy-term,
.view-blog {
  .view-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .views-row {
      width: calc(50% - #{$gutter/2});
      @include media-breakpoint-down(sm){
        width: 100%;
      }
      margin-bottom: 3rem;

      > a {
        @include article_teaser;
      }
    }
  }
}

#block-helper-blog-categories {
  @include card;
  padding: 2rem;
  margin-bottom: 2rem;

  h2 {
    font-family: $font-family-base;
    color: $black;
    font-weight: $font-weight-bold !important;
    font-size: 1.25rem;
  }

  .term {
    @include icon('angle-right');
    margin: .5rem 0;
    &:before {
      color: $primary;
      margin-right: .5rem;
    }

    a {
      color: $black;
      &:hover {
        text-decoration: underline;
      }
      &.active{
        font-weight: bold;
        color: $primary;
      }
    }
  }
}

.node-type-article {
  .blog-header {
    width: 100%;
    left: 0;
    display: block;
    background-position: center center;
    background-size: cover;
    padding-bottom: 33.333333%;
    @include media-breakpoint-only(md) {
      min-height: 340px;
      padding-bottom: 0;
    }
    @include media-breakpoint-only(lg) {
      min-height: 400px;
      padding-bottom: 0;
    }
    @include media-breakpoint-only(xl) {
      min-height: 640px;
      padding-bottom: 0;
    }
  }
  > .container {
    &.blog-similar-articles {
      max-width: 800px;
      .views-row {
        &:nth-child(2) {
          display:none;
        }
      }
    }
    &.blog-article {

      max-width: 800px;
      h1{
        margin: 1rem 0;
      }
      @include card;

      box-shadow: rgba(black,.2) 0 .2rem  1rem;
      padding: 1rem 2rem;
      margin-top: -150px;
      @include media-breakpoint-down(lg){
        margin-top: -100px;
      }
      @include media-breakpoint-down(md){
        margin-top: -50px;
      }
      @include media-breakpoint-down(sm){
        margin-top: 0;
      }
      .breadcrumb-wrapper{
        margin-bottom: 0;
      }
      padding-bottom: 3rem;
      margin-bottom: 3rem;
    }
  }
  .field-body {
    p {
      margin-bottom: 1rem;
    }
    > p:first-child {
      font-size: 1.25rem;
    }
    h3 {
      color: $primary;
    }
  }
}
